import { Box } from "@chakra-ui/react";

export const SectionHeader = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      sx={{
        fontWeight: "bold",
      }}
      fontSize={{ base: "40px", md: "70px" }}
      pos="relative"
      lineHeight={{ base: "48px", md: "78px" }}
    >
      {children}
    </Box>
  );
};
