import { Box, Flex, Grid } from "@chakra-ui/react";
import { CenterContainer } from "../shared/containers/CenterContainer";
import { SectionHeader } from "../shared/typography/Heading";
import { ROUNDS } from "./rounds";

export const RoundsComparison = () => {
  return (
    <CenterContainer>
      <Flex mt="100px" w="100%" flexDir="column">
        <Box fontSize="30px" fontFamily={"Inter Tight"} fontWeight="bold">
          Public Mint Rounds And ROI relative to the PUBLIC MINT ROUNDS
        </Box>
        <Flex w="100%" mt="50px">
          <Grid
            w="100%"
            display={{ base: "grid", md: "grid" }}
            templateColumns={{ base: "1fr", md: "1fr 1fr 1fr 1fr" }}
            gap="20px"
          >
            {ROUNDS.map((round) => {
              return (
                <Flex
                  key={round.number}
                  align="center"
                  flexDir="column"
                  gap="20px"
                >
                  <Flex
                    fontFamily="Plus Jakarta Sans"
                    w={{ base: "100%", md: "100%" }}
                    padding="16px 0px 20px 0px"
                    align="center"
                    flexDir="column"
                    justify="center"
                    borderTopRightRadius="16px"
                    bg="rgba(23, 23, 23, 0.6)"
                    border="1px dashed #7B7B7B;"
                  >
                    <Box fontSize="12px">
                      ROUND{" "}
                      <Box display="inline" fontWeight="bold">
                        {round.number}
                      </Box>
                    </Box>

                    <Box fontSize="24px" mt="6px" fontWeight="bold">
                      <Box display="inline" color="#04D7B1">
                        $
                      </Box>
                      {round.price}
                    </Box>
                    <Box
                      fontFamily="Plus Jakarta Sans"
                      mt="6px"
                      fontSize="12px"
                      fontWeight="bold"
                    >
                      Amount Mintable: {round.amount}
                    </Box>
                  </Flex>
                  <Box
                    mb={{ base: "40px", md: "none" }}
                    fontSize="24px"
                    fontWeight="bold"
                  >
                    <Box display="inline" color="#04D7B1">
                      X
                    </Box>
                    {round.x}
                  </Box>
                </Flex>
              );
            })}
          </Grid>
        </Flex>
      </Flex>
    </CenterContainer>
  );
};
