import { Box, Button, Flex, Image, Link } from "@chakra-ui/react";
import { Menu } from "./Menu/menu";

export const Navbar = () => {
  return (
    <Flex
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginTop: "30px",
        maxW: "1360px",
        width: "100vw",
      }}
      px={{ base: "30px", md: "none" }}
    >
      <Box
        sx={{ width: "150px", height: "50px", transform: "translateY(-6px)" }}
      >
        <Image alt="Mystra Logo" src="/assets/logo.svg" objectFit="contain" />
      </Box>

      <Menu />
      <Link
        display={{ base: "none", md: "flex" }}
        href="https://twitter.com/mystra_io"
      >
        <Button
          sx={{
            color: "white",
            fontFamily: "Plus Jakarta Sans",
            fontSize: "16px",

            fontWeight: "bold",
            textTransform: "none",
            height: "64px",
            bg: "rgba(0,0,0,0.1)",
            border: "1px solid #2E2E2E",
            gap: "10px",
            paddingX: "30px",
            borderRadius: "14px",
            backdropFilter: "blur(24px)",
          }}
          _hover={{ bg: "rgba(255,255,255,0.1)" }}
          textDecor="none !important"
          zIndex={900}
        >
          Follow us on{" "}
          <Image alt="X logo" src="/assets/x-logo.svg" width={25} height={24} />
        </Button>
      </Link>

      <Link
        display={{ base: "block", md: "none" }}
        href="https://twitter.com/mystra_io"
      >
        <Image
          alt="X logo"
          src="/assets/x-logo.svg"
          width={25}
          mr="20px"
          height={24}
        />
      </Link>
    </Flex>
  );
};
