import { Box, Flex, Grid } from "@chakra-ui/react";
import { CenterContainer } from "../shared/containers/CenterContainer";
import { InfoHeader } from "../shared/typography/InfoHeader";

export const InfoItem = ({ children }: { children: React.ReactNode }) => {
  return (
    <Grid templateColumns="20px 1fr" gap="20px">
      <Box
        border="5px solid #091E1B"
        borderRadius="50%"
        boxSize="18px"
        bg="#04D7B1"
      />

      <Box fontSize="14px">{children}</Box>
    </Grid>
  );
};

export const AdditionalInfoTwo = () => {
  return (
    <CenterContainer>
      <Flex flexDir="column" mt={{ base: "60px", md: "80px" }}>
        <InfoHeader header="About $MYSTRA token">
          <Grid
            templateColumns={{ base: "1fr", md: "1fr 1fr" }}
            gap={{ base: "40px", md: "80px" }}
          >
            <Flex flexDir="column" gap="20px">
              <InfoItem>
                $MYSTRA tokens can only be minted and claimed by exchanging
                Premium Pass. The more Premium Pass you purchase, the more
                $MYSTRA tokens you will get in the preseed, seed, private, and
                public rounds. The allocation of $MYSTRA tokens is equal for
                each round of Premium Pass sales. One Premium Pass = 5000
                $MYSTRA tokens.
              </InfoItem>
              <InfoItem>
                Unlock both CEXes and DEX launches (therefore stronger price
                action)
              </InfoItem>
              <InfoItem>
                $MYSTRA token staking with dynamic & sustainable APY rewards.
              </InfoItem>
            </Flex>
            <Flex flexDir="column" gap="20px">
              <InfoItem>
                Up to 25% revenues dedicated to token exchanging from Mystra
                platform revenue model and up to 25% to $MYSTRA liquid pool, and
                up to 50% of dedicated revenues goes to Mystra treasury managed
                by DAO. More information about revenues is available in the
                pitchdeck.
              </InfoItem>
              <InfoItem>
                Decentralized governance for rewards & DeFi products, Mystra
                platform and more. The amount of token staked by the user
                affects the weight of the user's vote in the governance of the
                DAO.
              </InfoItem>
            </Flex>
          </Grid>
        </InfoHeader>
      </Flex>
    </CenterContainer>
  );
};
