import { Box, Flex, Image } from "@chakra-ui/react";
import Marquee from "react-fast-marquee";
import { CenterContainer } from "../../shared/containers/CenterContainer";
import { ModuleItem } from "../platformExplained";

export const ForCreators = () => {
  return (
    <Box
      height={{ base: "auto", md: "600px" }}
      sx={{ display: "flex", alignItems: "center" }}
      pos="relative"
    >
      <CenterContainer>
        <Box
          display={{ base: "none", md: "flex" }}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
          pos="relative"
        >
          <Image
            display={{ base: "none", md: "flex" }}
            src="/assets/elements/stars-two.svg"
            pos="absolute"
            left="150px"
            top="70px"
          />
          <Box
            display={{ base: "none", md: "flex" }}
            sx={{
              flexDirection: "column",
              alignSelf: "flex-end",
              transform: "translateY(90px)",

              gap: "20px",
              justifyContent: "center",
              alignItems: "center",
            }}
            ml="250px"
          >
            <Flex direction={"row"}>
              <ModuleItem
                creator
                icon="launchpad.png"
                label=""
                name="Launchpad"
              />

              <ModuleItem blurred icon="dao.png" label="" name="Bridge" />
              <ModuleItem blurred icon="dao.png" label="" name="Bridge" />
            </Flex>
            <Flex direction={"row"}>
              <ModuleItem
                creator
                icon="incubation.png"
                label=""
                name="Incubation Hub"
              />

              <ModuleItem
                creator
                icon="dao.png"
                label=""
                name="Dao Governance"
              />
              <ModuleItem
                blurred
                icon="launchpad.png"
                label=""
                name="Dex&Swap"
              />
              <ModuleItem
                creator
                icon="dao.png"
                label=""
                name="Hidden"
                blurred
              />
            </Flex>
            <Flex direction={"row"}>
              <ModuleItem
                creator
                icon="creator.png"
                label=""
                name="Creator Studio"
              />
              <ModuleItem
                creator
                icon="launchpad.png"
                label=""
                blurred
                name="Web3 Chat"
              />
              <ModuleItem
                creator
                blurred
                icon="launchpad.png"
                label=""
                name="Staking"
              />
            </Flex>
          </Box>
          <Flex flexDir="column" width={{ base: "auto", md: "540px" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "-40px",

                fontWeight: "bold",
                lineHeight: "56px",
              }}
              fontSize={{ base: "55px", md: "70px" }}
              pos="relative"
            >
              <Box
                fontSize={{ base: "30px", md: "33px" }}
                lineHeight={"30px"}
                color="#85858A"
              >
                We build for the
              </Box>
              <Box sx={{ letterSpacing: "-2px" }}>creators</Box>
              <Box
                display={{ base: "flex", md: "none" }}
                className="creatorPlanet"
                sx={{
                  height: "150px",

                  right: "0",
                  top: "-80px",
                  width: "150px",

                  position: "absolute",

                  borderRadius: "50%",
                }}
                zIndex="-1"
              >
                <Box
                  sx={{
                    position: "absolute",
                  }}
                >
                  <Image
                    src="/assets/planet-creator-right.svg"
                    alt="Planet"
                    objectFit="contain"
                  />
                </Box>
                <Box
                  sx={{
                    position: "absolute",
                  }}
                >
                  <Image
                    src="/assets/planet-creator-left.svg"
                    alt="Planet"
                    objectFit="contain"
                  />
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                color: "#85858A",
                fontFamily: "Plus Jakarta Sans",

                fontWeight: "medium",

                paddingTop: "30px",
                gap: "50px",
                maxWidth: "540px",
              }}
              fontSize={{ base: "21px", md: "18px" }}
            >
              <Box>
                <Box display="inline" color="white">
                  We can help you whether you are technical or artistic mind.
                </Box>{" "}
                Create true gems using our comprehensive toolkit, APIs and easy
                no-code tools. We offer support and guidance at every stage.{" "}
                <Box display="inline" color="white">
                  Do you want to show your project to the community?
                </Box>{" "}
                We got it covered. Use our Launchpad, DAO system and Airdrop
                modules and{" "}
                <Box display="inline" color="white">
                  engage with involved Mystra Community!
                </Box>
              </Box>
            </Box>
          </Flex>
          <Box
            mt="40px"
            h="230px"
            pos="relative"
            display={{ base: "block", md: "none" }}
          >
            <Flex
              flexDir="column"
              align="space-between"
              w="100vw"
              left="-30px"
              pos="absolute"
              gap="18px"
              h="100%"
            >
              <Marquee autoFill>
                {" "}
                <ModuleItem
                  creator
                  icon="incubation.png"
                  label="incubation.png"
                  name="Incubation Hub"
                />
                <ModuleItem
                  creator
                  icon="dao.png"
                  label=""
                  name="DAO Governance"
                />
              </Marquee>
              <Marquee autoFill direction="right">
                <ModuleItem
                  creator
                  icon="launchpad.png"
                  label=""
                  name="Launchpad"
                />
                <ModuleItem
                  creator
                  icon="creator.png"
                  label=""
                  name="Creator Studio"
                />
              </Marquee>
            </Flex>
          </Box>
        </Box>
      </CenterContainer>
    </Box>
  );
};
