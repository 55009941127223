import { Box, Flex, Image, Link } from "@chakra-ui/react";
import { CenterContainer } from "../shared/containers/CenterContainer";

const FooterGroup = ({
  name,
  children,
}: {
  name: string;
  children: React.ReactNode;
}) => {
  return (
    <Flex flexDir="column" gap={{ base: "12px", md: "24px" }}>
      <Box
        opacity="0.3"
        fontFamily={"Plus Jakarta Sans"}
        fontWeight="1000"
        fontSize="14px"
      >
        {name}
      </Box>
      {children}
    </Flex>
  );
};

export const Footer = () => {
  return (
    <Flex flexDir="column" mt="100px" w="100%" fontFamily="Plus Jakarta Sans">
      <CenterContainer>
        <Flex
          fontSize="18px"
          w="100%"
          flexDir={{ base: "column", md: "row" }}
          justifyContent={{ base: "flex-start", md: "space-between" }}
          mb="40px"
          gap="40px"
        >
          <FooterGroup name="Social Media">
            <Flex align="center" gap="50px">
              <Link href="https://twitter.com/mystra_io">
                <Image h="18px" src="/assets/x-logo.svg" />
              </Link>
              <Link href="https://discord.gg/sZQVdRCyqx">
                <Image h="25px" src="/assets/discord.svg" />
              </Link>
              <Link href="https://t.me/mystraio">
                <Image h="23px" src="/assets/telegram.svg" />
              </Link>
              <Link href="https://medium.com/@mystra">
                <Image h="25px" src="/assets/medium.svg" />
              </Link>
            </Flex>
          </FooterGroup>
          <Flex
            align={{ base: "flex-start", md: "center" }}
            flexDir={{ base: "column", md: "row" }}
            gap={{ base: "40px", md: "120px" }}
          >
            <FooterGroup name="Support">
              <Flex align="center" gap="50px">
                <Link href="https://docs.mystra.io/docs/PRODUCTS%20AND%20SERVICES/2.17%20FAQ">
                  FAQ
                </Link>
                <Link href="https://github.com/mystra-io">Github</Link>
                <Link href="https://docs.mystra.io">Docs</Link>
              </Flex>
            </FooterGroup>

            <FooterGroup name="Mystra">
              <Flex align="center" gap="50px">
                <Link href="https://download.mystra.io/pitchdeck.pdf">
                  Pitchdeck
                </Link>
                <Link href="https://github.com/mystra-io/media">
                  Press Media
                </Link>
              </Flex>
            </FooterGroup>
          </Flex>
        </Flex>
      </CenterContainer>
      <Box w="100vw" borderTop="1px solid rgba(255,255,255,0.08)" />
      <CenterContainer>
        <Flex
          py="30px"
          fontSize="16px"
          w="100%"
          flexDir={{ base: "column-reverse", md: "row" }}
          justifyContent="space-between"
          textAlign="center"
          align="center"
          gap="20px"
        >
          <Box color="#7B7B7B" mb={{ base: "30px", md: "0px" }}>
            © Copyright Mystra 2023. All Rights Reserved.{" "}
          </Box>

          <Flex
            color="#7B7B7B"
            gap={{ base: "15px", md: "50px" }}
            fontWeight="medium"
            justify={{ base: "initial", md: "center" }}
          >
            <Link href="https://docs.mystra.io/docs/what-is-mystra/1.6-Privacy-policy">
              Privacy Policy
            </Link>
            <Link href="https://docs.mystra.io/docs/what-is-mystra/1.7-Terms-of-use">
              Terms of Use
            </Link>
          </Flex>
        </Flex>
      </CenterContainer>
    </Flex>
  );
};
