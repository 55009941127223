import { Box, Button, Flex, Image, Link } from "@chakra-ui/react";

export const Menu = () => {
  return (
    <Flex
      position="fixed"
      top="30px"
      display={{ base: "none", md: "flex" }}
      sx={{
        zIndex: "800",

        left: 0,
        right: 0,
      }}
      justifyContent="center"
    >
      <Flex
        gap="30px"
        sx={{
          color: "white",
          background: "rgba(0,0,0,0.9)",
          alignItems: "center",
          border: "1px solid #2E2E2E",
          height: "64px",
          paddingLeft: "35px",
          backdropFilter: "blur(12px)",
          paddingRight: "8px",
          left: 0,
          fontFamily: "DM Sans",
          borderRadius: "14px",
          fontWeight: "700",
          fontSize: "16px",
          right: 0,
          top: "40px",
          margin: " 0 auto",
        }}
      >
        <Link href="#token">About Token</Link>
        <Link href="#invest">Invest</Link>
        <Link href="#info" style={{ marginRight: "16px" }}>
          Info
        </Link>

        <Link href="https://mystra.io">
          <Button
            sx={{
              borderRadius: "10px",
              color: "white",
              border: "1px solid #04D7B1",
              height: "46px",
              gap: "8px",
              "&:hover": {
                background:
                  "linear-gradient(180deg, rgba(4, 215, 177, 0.71) 0%, rgba(4, 215, 177, 0.20) 100%)",
              },
              alignItems: "center",
              paddingX: "16px",
              background:
                "linear-gradient(180deg, rgba(4, 215, 177, 0.41) 0%, rgba(4, 215, 177, 0.00) 100%)",
            }}
          >
            Home Page{" "}
            <Image
              src="/assets/star-white.svg"
              width="17px"
              alt="Small white star"
            />
          </Button>
        </Link>
      </Flex>
    </Flex>
  );
};
