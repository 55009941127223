import { Box, Flex, Grid, Image } from "@chakra-ui/react";
import { CenterContainer } from "../shared/containers/CenterContainer";
import { DottedLabel } from "../shared/typography/DottedLabel";

type Round = {
  name: string;
  percent: string;
  color: string;
  text: string;
  amount: string;
};

const items: Round[] = [
  {
    name: "Team",
    percent: "12.53",
    color: "#FF4D00",
    text: "CLIFF: 6m VESTING: 18m",
    amount: "32 500 000",
  },
  {
    name: "PRE-SEED",
    percent: "6.38",
    color: "#4282FF",
    text: "CLIFF: 1m VESTING: 10m",
    amount: "14 000 000",
  },
  {
    name: "SEED",
    percent: "6.61",
    color: "#4282FF",
    text: "CLIFF: 1m VESTING: 10m",
    amount: "14 500 000",
  },
  {
    name: "PRIVATE",
    percent: "6.83",
    color: "#4282FF",
    text: "CLIFF: 1m VESTING: 10m",
    amount: "15 000 000",
  },
  {
    name: "Public I",
    percent: "4.56",
    color: "#04D7B1",
    text: "CLIFF: 0m VESTING: 5m",
    amount: "10 000 000",
  },
  {
    name: "Public  II",
    percent: "10.25",
    color: "#04D7B1",
    text: "CLIFF: 0m VESTING: 3m",
    amount: "22 500 000",
  },
  {
    name: "Public III",
    percent: "19.36",
    color: "#04D7B1",
    text: "CLIFF: 0m VESTING: 2m",
    amount: "42 500 000",
  },
  {
    name: "Public IV",
    percent: "28.47",
    color: "#04D7B1",
    text: "CLIFF: 0m VESTING: 1m",
    amount: "62 500 000",
  },
  {
    name: "Init. dao treasury",
    percent: "5.01",
    color: "#8FFFFF",
    text: "CLIFF: 0m VESTING: 1m",
    amount: "11 000 000",
  },
];

export const Tokenomics = () => {
  return (
    <CenterContainer>
      <Grid
        templateColumns={{ base: "1fr", md: "1fr 1fr" }}
        my="150px"
        gap="60px"
        pos="relative"
      >
        <Box pos="absolute" top="-100px" left="0" id="tokenomics" />
        <Flex flexDir="column" gap="600px">
          <Flex flexDir="column" gap="30px">
            <DottedLabel>The Tokenomics</DottedLabel>
            <Flex
              mt="-14px"
              fontSize={{ base: "40px", md: "70px" }}
              lineHeight={{ base: "46px", md: "78px" }}
              fontWeight="bold"
              fontFamily="Inter Tight"
            >
              Tokenomics of $MYSTRA Coin <br />
            </Flex>
            <Flex
              color="#85858A"
              fontSize="21px"
              fontFamily={"Plus Jakarta Sans"}
            >
              Our tokenomics will address various aspects, including
              distribution rules and a DAO economic model to incentivize
              engagement. Premium Pass can be exchanged for $MYSTRA tokens and
              vice versa.
            </Flex>
            <Image
              display={{ base: "none", md: "flex" }}
              mt="40px"
              src="/assets/elements/tokenomics.png"
            />
          </Flex>
        </Flex>

        <Flex flexDir="column" pos="relative" gap="13px">
          {items.map((item) => {
            return (
              <Flex
                h="74px"
                justifyContent="space-between"
                px="27px"
                alignItems="center"
                pl="35px"
                borderRadius="12px"
                pos="relative"
                bg="rgba(0,0,0,0.02)"
                fontFamily="Plus Jakarta Sans"
                boxShadow="0px 0.72474px 0.72474px 0px rgba(255, 255, 255, 0.25) inset, 0px 0px 10px 0px rgba(255, 255, 255, 0.05) inset"
              >
                <Flex
                  h="100%"
                  pos="absolute"
                  left="0"
                  top="0"
                  bg={item.color}
                  w="12px"
                  borderLeftRadius="4px"
                />
                <Flex flexDir="column">
                  <Flex align="center" gap="10px">
                    <Box
                      fontWeight="1000"
                      textTransform="uppercase"
                      fontSize="14px"
                      fontFamily="Plus Jakarta Sans"
                    >
                      {item.name}
                    </Box>
                    <Box color={item.color} fontSize="14px">
                      {item.percent}%
                    </Box>
                  </Flex>
                  <Box color="gray" fontSize={{ base: "11px", md: "14px" }}>
                    {item.text}
                  </Box>
                </Flex>
                <Flex flexDir="column" alignItems="flex-end">
                  <Box color="gray" fontSize="12px" letterSpacing="10%">
                    $MYSTRA Amount
                  </Box>
                  <Box>{item.amount} </Box>
                </Flex>
              </Flex>
            );
          })}
          <Flex opacity="0.5" mt="20px">
            *Premium Pass is received immediately. Exchange for the $MYSTRA coin
            is possible according to the rules of Cliff & Vesting after TGE.
          </Flex>
        </Flex>
      </Grid>
    </CenterContainer>
  );
};
