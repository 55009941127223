import {
  Box,
  Button,
  Flex,
  Grid,
  Image,
  Link,
  useDisclosure,
} from "@chakra-ui/react";
import { CenterContainer } from "../shared/containers/CenterContainer";
import { Logo } from "../../Logo";
import { AccountMini } from "./AccountMini/accountMini";
import { useEffect, useMemo, useState } from "react";

import {
  useDisconnect,
  useWeb3Modal,
  useWeb3ModalAccount,
  useWeb3ModalEvents,
  useWeb3ModalProvider,
  useWeb3ModalState,
} from "@web3modal/ethers5/react";

import {
  AmountButton,
  CHAIN_SELECT_DATA,
  ItemLabel,
  NETWORK_DATA,
} from "../BuyTickets/BuyForm/buyForm";
import {
  CustomSelect,
  CustomSelectItem,
} from "../shared/inputs/CustomSelect/customSelect";
import { CustomModal } from "../CustomModal/customModal";
import { useCasperWallet } from "../../providers/CasperWalletProvider/casperWalletProvider";
import {
  CasperDashConnector,
  useAccount,
  useConnect,
} from "@casperdash/usewallet";
import axios from "axios";
import { Web3Modal } from "@web3modal/ethers5/dist/types/src/client";
import { ethers } from "ethers";
import { useNavigate } from "react-router-dom";

const idToChainName = (id: string) => {
  if (id == "0") {
    return "Casper";
  } else if (id == "137") {
    return "Polygon";
  } else if (id == "56") {
    return "Binance";
  } else if (id == "1") {
    return "Ethereum";
  } else if (id == "42161") {
    return "Arbitrum";
  } else if (id == "25") {
    return "Cronos";
  } else {
    return "Unknown";
  }
};

const idToIcon = (id: string) => {
  if (id == "0") {
    return "/assets/blockchains/cspr.svg";
  } else if (id == "137") {
    return "/assets/blockchains/polygon.svg";
  } else if (id == "56") {
    return "/assets/blockchains/bnb.svg";
  } else if (id == "1") {
    return "/assets/blockchains/ether.svg";
  } else if (id == "42161") {
    return "/assets/blockchains/arb.svg";
  } else if (id == "25") {
    return "/assets/blockchains/cronos.svg";
  } else {
    return "Unknown";
  }
};

enum Wallet {
  NONE,
  CASPERDASH,
  METAMASK,
  WALLETCONNECT,
  CASPERWALLET,
}

const AllocationCard = ({
  label,
  value,
  description,
}: {
  label: string;
  value: string | React.ReactNode;
  description: string;
}) => {
  return (
    <Flex flexDir="column">
      <Flex
        fontSize="16px"
        fontFamily="Plus Jakarta Sans"
        color="#E0E0E0"
        fontWeight="700"
      >
        {label}:
      </Flex>
      <Flex
        flexDir={{ base: "row", lg: "column" }}
        align={{ base: "center", md: "initial" }}
        bg="#121212"
        border="1px solid"
        borderRadius="8px"
        borderColor="#262626"
        padding="24px 30px"
        gap="10px"
        mt="10px"
      >
        <Box
          lineHeight="100%"
          fontFamily="Plus Jakarta Sans"
          fontWeight="bold"
          fontSize={{ base: "18px", md: "24px" }}
        >
          {value}
        </Box>
        <Box
          fontFamily="Inter Tight"
          lineHeight="16px"
          fontWeight="500"
          color="#04D7B1"
        >
          {description}
        </Box>
      </Flex>
    </Flex>
  );
};

export const WhitelistEndedPageTwo = () => {
  const navigate = useNavigate();

  return (
    <CenterContainer>
      <Flex
        pos="fixed"
        h="100vh"
        w="100vw"
        align="center"
        zIndex="-1"
        left="0"
        justify="center"
      >
        <Image src="/assets/Background.png" opacity={"0.7"} margin="auto" />
      </Flex>
      <Flex w="100%" flexDir="column">
        <Flex
          w="100%"
          align="center"
          justify="space-between"
          my={{ base: "20px", md: "40px" }}
        >
          <Image
            w={{ base: "120px", md: "auto" }}
            transform="translateX(-20px)"
            cursor="pointer"
            onClick={() => {
              navigate("/");
            }}
            src="/assets/logo.svg"
          />
          <Box cursor="pointer"></Box>
        </Flex>

        <Flex
          w="100%"
          flexDir="column"
          mt="100px"
          pos="relative"
          mb={{ base: "100px", md: "auto" }}
        >
          <Flex
            w="100%"
            align="center"
            flexDir="column"
            zIndex="1"
            textAlign={{ base: "center" }}
          >
            <Box
              color="#04D7B1"
              fontFamily="Plus Jakarta Sans"
              fontSize="26px"
              fontWeight="800"
              lineHeight={{ base: "100%", md: "60%" }}
            >
              Congratulations to the early birds!
            </Box>
            <Box
              lineHeight="120%"
              mt={{ base: "50px", md: "0px" }}
              fontFamily="Inter Tight"
              fontSize={{ base: "45px", md: "70px" }}
              fontWeight="bold"
            >
              Early bird mint has been closed.
            </Box>
            <Box
              mt="20px"
              fontFamily="Plus Jakarta Sans"
              textAlign="center"
              fontSize={{ base: "18px", md: "21px" }}
              maxW="600px"
            >
              Whitelist minting has been completed. Congratulations to all the
              early birds who received Premium Passes. Didn't make it in time?
              You will be able to take part in a public mint soon. Follow our X
              to not miss the opportunity!
            </Box>
            <Link href="https://twitter.com/mystra_io">
              <Button
                h="70px"
                color="white"
                mt="40px"
                border="1px solid #00FFD1"
                bg="none"
                borderRadius="18px"
                w="226px"
                _hover={{ bg: "rgba(255,255,255,0.1)" }}
              >
                Follow Mystra on X
              </Button>
            </Link>
          </Flex>
        </Flex>
      </Flex>
    </CenterContainer>
  );
};
