import { Box, Flex, Grid } from "@chakra-ui/react";
import { CenterContainer } from "../shared/containers/CenterContainer";
import { InfoHeader } from "../shared/typography/InfoHeader";

export const InfoItem = ({ children }: { children: React.ReactNode }) => {
  return (
    <Grid templateColumns="20px 1fr" gap="20px">
      <Box
        border="5px solid #091E1B"
        borderRadius="50%"
        boxSize="18px"
        bg="#04D7B1"
      />

      <Box fontSize="14px">{children}</Box>
    </Grid>
  );
};

export const AdditionalInfo = () => {
  return (
    <CenterContainer>
      <Flex flexDir="column" id="info">
        <InfoHeader header="Additional information">
          <Grid
            templateColumns={{ base: "1fr", md: "1fr 1fr" }}
            gap={{ base: "40px", md: "80px" }}
          >
            <Flex flexDir="column" gap="20px">
              <InfoItem>
                After the TGE of the $MYSTRA token, the premium pass exchange
                mechanism will be launched. We will announce this information
                publicly on our social media channels
              </InfoItem>
              <InfoItem>
                Purchased Premium Pass will be sent to the investor's wallet
                within 48 hours.
              </InfoItem>
              <InfoItem>
                Premium Pass will be minted and sent to the addresses of the
                wallets from which the payment/purchase was made.
              </InfoItem>
              <InfoItem>
                The investor is not charged for the minting cost
              </InfoItem>
            </Flex>
            <Flex flexDir="column" gap="20px">
              <InfoItem>
                It is possible to exchange Premium Pass to get a $MYSTRA tokens
                (after TGE + vesting) or sell Premium Pass on NFT marketplace
                (any time after Premium Pass received - no vesting). We
                guarantee that after the Public Sale Round 4 is over, users will
                be publicly informed that Premium Pass can be purchased on the
                secondary market.
              </InfoItem>
              <InfoItem>
                We guarantee free promotion of Premium Pass issued by private
                and Venture investors on our marketplace. Promotion will take
                place directly on Mystra marketplace, mystra.io, on the Mystra
                platform mystra.app, and on all Mystra social media channels,
                i.e. Twitter, Telegram, Discord, Reddit, Medium, and through our
                partners.
              </InfoItem>
            </Flex>
          </Grid>
        </InfoHeader>
      </Flex>
    </CenterContainer>
  );
};
