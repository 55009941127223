import React from "react";

import { Box, Flex, Image } from "@chakra-ui/react";
import Marquee from "react-fast-marquee";

const partnersList = [
  {
    key: "casper blockchain",
    image: "/assets/partners/casperblockchain.svg",
    url: "",
  },
  {
    key: "awesome casper",
    image: "assets/partners/awesomecasper.svg",
    url: "",
  },
  {
    key: "dotoracle",
    image: "/assets/partners/dotoracle.svg",
    url: "",
  },

  {
    key: "tubbly",
    image: "/assets/partners/tubbly.svg",
    url: "/assets/partners/tubbly.svg",
  },

  {
    key: "cspr pl",
    image: "/assets/partners/csprpl.svg",
    url: "/assets/partners/csprpl.svg",
  },
  {
    key: "ari10",
    image: "/assets/partners/ari10.svg",
    url: "/assets/partners/ari10.svg",
  },
  {
    key: "nftCalendar",
    image: "/assets/partners/nftcalendar.png",
    url: "/assets/partners/nftcalendar.svg",
  },
  {
    key: "casperDash",
    image: "/assets/partners/casperdash.svg",
    url: "",
  },
];

export const Partners = () => {
  return (
    <Box
      height={{ base: "160px", md: "138px" }}
      sx={{
        display: "flex",

        backgroundColor: "black",
        justifyContent: "center",
      }}
      flexDir="column"
    >
      <Box display={{ base: "none", md: "block" }}>
        <Marquee autoFill style={{ opacity: 0.5 }}>
          {partnersList.map((partner) => {
            return (
              <Box key={partner.key} sx={{ marginX: "40px" }}>
                <Image alt={partner.key} src={partner.image} maxH="36px" />
              </Box>
            );
          })}
        </Marquee>
      </Box>
      <Flex h="70px" display={{ base: "flex", md: "none" }}>
        <Marquee autoFill style={{ opacity: 0.5 }}>
          {partnersList.slice(4, -1).map((partner) => {
            return (
              <Box key={partner.key} sx={{ marginX: "40px" }}>
                <Image maxH="36px" alt={partner.key} src={partner.image} />
              </Box>
            );
          })}
        </Marquee>
      </Flex>
      <Flex h="70px" display={{ base: "flex", md: "none" }}>
        <Marquee autoFill direction="right" style={{ opacity: 0.5 }}>
          {partnersList.slice(0, 4).map((partner) => {
            return (
              <Box key={partner.key} sx={{ marginX: "40px" }}>
                <Image maxH="36px" alt={partner.key} src={partner.image} />
              </Box>
            );
          })}
        </Marquee>
      </Flex>
    </Box>
  );
};
