import { Flex } from "@chakra-ui/react";

export const CenterContainer = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <Flex
      sx={{ maxWidth: "1200px" }}
      margin="0 auto"
      pos="relative"
      w="100vw"
      px={{ base: "30px", md: "none" }}
    >
      {children}
    </Flex>
  );
};
