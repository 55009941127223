import { Box, Button, Flex, Grid, Image, Link } from "@chakra-ui/react";
import { Navbar } from "./Navbar";

export const InvestHeader = () => {
  return (
    <Grid
      sx={{
        display: "grid",

        gridTemplateRows: "auto 1fr",
        position: "relative",
        width: "100vw",
        height: "100vh",

        overflow: "hidden",
      }}
    >
      <video autoPlay loop controls={false} muted className="video-header">
        <source src="/assets/video/video.mp4" type="video/mp4" />
      </video>
      <Box sx={{ maxWidth: "1360px", margin: "auto", padding: 0 }}>
        <Navbar />
      </Box>
      <Flex
        gap={{ base: "20px", md: "20px" }}
        flexDir="column"
        sx={{
          width: "100vw",
          alignItems: "center",
          position: "relative",

          margin: "auto",
        }}
      >
        <Box
          sx={{
            position: "absolute",

            right: "-290px",
            top: 0,
            bottom: 0,
            margin: "0 auto",
            zIndex: -1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          opacity={{ base: "0.2", md: 1 }}
        >
          <Image
            alt="Blur"
            src="/assets/decor-header-right.svg"
            filter="grayscale(1)"
            width={800}
            height={700}
          />
        </Box>
        <Box
          sx={{
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            margin: "0 auto",
            paddingBottom: "30px",
            zIndex: -1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Image
            alt="Blur"
            src="/assets/header-blur.png"
            filter="grayscale(1)"
            width="100vw"
            height={{ base: "100vh", md: "100vh" }}
          />
        </Box>
        <Box
          sx={{
            position: "absolute",

            left: "-290px",
            top: "-200px",
            margin: "0 auto",
            zIndex: -1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          opacity={{ base: "0.2", md: 1 }}
        >
          <Image
            alt="Blur"
            src="/assets/decor-header-left.svg"
            width={800}
            filter="grayscale(1)"
            height={600}
          />
        </Box>
        <Box
          sx={{
            zIndex: 1,
            textAlign: "center",

            fontWeight: "bold",
            position: "relative",
            letterSpacing: "-3px",
          }}
          maxW="800px"
          lineHeight={{ base: "48px", md: "78px", xl: "95px" }}
          fontSize={{ base: "48px", md: "70px", xl: "108px" }}
        >
          Venture Capital Investors offer
        </Box>
        <Box
          sx={{
            fontSize: { md: "18px", xl: "21px" },
            fontFamily: "Plus Jakarta Sans",
            color: "#E0E0E0",
            maxWidth: "820px",
            textAlign: "center",
            lineHeight: "32px",
            marginTop: "10px",
          }}
          paddingX="30px"
        >
          We empower communities and creators, elevating the transition from
          Web2 to Web3 through DAO platform and a multichain Web3 Tool
          Aggregator
        </Box>
        <Link href="https://download.mystra.io/pitchdeck_vc.pdf">
          <Button
            background="none"
            mt={{ base: "20px", md: "none" }}
            sx={{
              fontSize: "20px",
              color: "white",
              border: "1px solid white",
              fontFamily: "DM Sans",
              fontWeight: "bold",
              borderRadius: "18px",
              height: "76px",
              paddingX: "40px",
              gap: "40px",
            }}
            _hover={{
              background: "rgba(255,255,255,0.1)",
            }}
          >
            CHECK OUR PITCHDECK
            <Image
              src="/assets/arrow-right-brand.svg"
              alt="Brand arrow"
              width="32px"
              height="32px"
            />
          </Button>
        </Link>
      </Flex>
    </Grid>
  );
};
