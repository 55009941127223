import * as React from "react";
import { Box, ChakraProvider, theme } from "@chakra-ui/react";
import { Header } from "./components/Header";
import { PlatformExplained } from "./components/PlatformExplained/platformExplained";

import "@fontsource/dm-sans";
import "@fontsource/plus-jakarta-sans";
import "@fontsource/inter-tight";

import { Partners } from "./components/Partners/partners";
import { TicketIntro } from "./components/TicketIntro/ticketIntro";
import { getDefaultProvider } from "ethers";

import { Global } from "@emotion/react";
import { SaleCountdown } from "./components/SaleCountdown/saleCountdown";
import { Tokenomics } from "./components/Tokenomics/tokenomics";
import { Footer } from "./components/Footer/footer";
import { Team } from "./components/Team/team";
import { Menu } from "./components/Header/Navbar/Menu/menu";
import {
  BrowserRouter,
  createBrowserRouter,
  Route,
  RouterProvider,
  Routes,
} from "react-router-dom";
import { InvestPage } from "./components/investPage";
import { CasperWalletProvider } from "./providers/CasperWalletProvider/casperWalletProvider";
import { WhitelistPage } from "./components/WhitelistPage";
import { WagmiConfig, createConfig, configureChains } from "wagmi";
import { publicProvider } from "wagmi/providers/public";

import {
  CasperDashConnector,
  CasperSignerConnector,
  CasperProvider,
  createClient,
} from "@casperdash/usewallet";
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers5/react";
import { WhitelistEndedPage } from "./components/WhitelistPage/whitelistEnded";
import { WhitelistTimerPage } from "./components/WhitelistPage/whitelistTimer";
import { WhitelistEndedPageTwo } from "./components/WhitelistPage/whitelistEndedTwo";

const client = createClient({
  connectors: [new CasperSignerConnector(), new CasperDashConnector()],
  autoConnect: false,
});

const Fonts = () => (
  <Global
    styles={`
    @font-face {
      font-family: 'Inter Tight';

      src: local('Inter Tight'), url('/assets/fonts/InterTight.ttf') format('ttf');
      }
      `}
  />
);

// 1. Get projectId at https://cloud.walletconnect.com
const projectId = "689ec61f1ded4d29c36bcbb2c99131e6";

// 2. Create wagmiConfig
const metadata = {
  name: "Web3Modal",
  description: "Web3Modal Example",
  url: "https://web3modal.com",
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};

const mainnet = {
  chainId: 1,
  name: "Ethereum",
  currency: "ETH",
  explorerUrl: "https://etherscan.io",
  rpcUrl: "https://cloudflare-eth.com",
};

const cronos = {
  chainId: 25,
  name: "Cronos",
  currency: "CRO",
  explorerUrl: "https://cronoscan.com",
  rpcUrl: "https://cronos-evm.publicnode.com	",
};

const bnb = {
  chainId: 56,
  name: "Smart Chain Mainnet",
  currency: "BNB",
  explorerUrl: "https://bscscan.com",
  rpcUrl: "https://bsc-dataseed1.binance.org",
};

const polygon = {
  chainId: 137,
  name: "Polygon",
  currency: "MATIC",
  explorerUrl: "https://polygonscan.com",
  rpcUrl: "https://polygon-rpc.com/",
};

const arbitrum = {
  chainId: 42161,
  name: "Polygon",
  currency: "ETH",
  explorerUrl: "https://explorer.arbitrum.io",
  rpcUrl: "https://arbitrum-mainnet.infura.io",
};

createWeb3Modal({
  ethersConfig: defaultConfig({ metadata, enableInjected: true }),
  chains: [mainnet, bnb, cronos, polygon, arbitrum],
  projectId,
});

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <Header />
        <Menu />
        <Partners />
        <PlatformExplained />
        <TicketIntro />
        <SaleCountdown />

        <Tokenomics />
        <Team />
        <Footer />
      </>
    ),
  },
  {
    path: "/invest",
    element: (
      <CasperWalletProvider>
        <InvestPage />
      </CasperWalletProvider>
    ),
  },
  {
    path: "/earlybirds",
    element: (
      <CasperWalletProvider>
        <CasperProvider client={client}>
          <WhitelistEndedPageTwo />
        </CasperProvider>
      </CasperWalletProvider>
    ),
  },
  {
    path: "/earlymint",
    element: (
      <CasperWalletProvider>
        <CasperProvider client={client}>
          <WhitelistEndedPageTwo />
        </CasperProvider>
      </CasperWalletProvider>
    ),
  },
  {
    path: "/whitelist",
    element: (
      <CasperWalletProvider>
        <CasperProvider client={client}>
          <WhitelistEndedPageTwo />
        </CasperProvider>
      </CasperWalletProvider>
    ),
  },
]);

export const App = () => (
  <ChakraProvider theme={theme}>
    <Fonts />
    <RouterProvider router={router} />
  </ChakraProvider>
);
