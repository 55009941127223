import {
  Box,
  Button,
  Checkbox,
  Flex,
  Grid,
  GridItem,
  Image,
  Input,
  Link,
  Select,
  Spinner,
  useDisclosure,
} from "@chakra-ui/react";
import https from "https";
import Big from "big.js";

import {
  CasperClient,
  CasperServiceByJsonRPC,
  CLPublicKey,
  DeployUtil,
} from "casper-js-sdk";

import { useEffect, useState } from "react";
import { BigNumber, ethers } from "ethers";
import { useMemo } from "react";
import { ERC20_ABI, TICKET_ABI } from "./abis";
import { Dispatch } from "react";
import axios from "axios";
import { CenterContainer } from "../shared/containers/CenterContainer";
import { SectionHeader } from "../shared/typography/Heading";
import { CustomModal } from "../CustomModal/customModal";
import { useCasperWallet } from "../../providers/CasperWalletProvider/casperWalletProvider";

const nodeURL = "http://195.201.86.100:7777/rpc";
export const casperService = new CasperServiceByJsonRPC(nodeURL);
export const casperClient = new CasperClient(nodeURL);

export const MOTE_RATE = 1000000000;

const putDeployUniversal = (signRes: any, deploy: any, publicKey: string) => {
  if (signRes.signature) {
    const signedDeploy = DeployUtil.setSignature(
      deploy,
      signRes.signature,
      CLPublicKey.fromHex(publicKey)
    );

    return casperClient.putDeploy(signedDeploy);
  } else {
    return new Promise(() => {});
  }
};

const StoreHeader = ({ children }: any) => {
  return (
    <Box fontWeight="700" lineHeight="90%" fontSize="14px" color="white">
      {children}
    </Box>
  );
};

export const toMotes = (amount: number) => {
  try {
    const bigAmount = Big(amount)
      .times(MOTE_RATE)
      .round(0, Big.roundDown)
      .toString();
    return BigNumber.from(bigAmount);
  } catch (error) {
    return "-";
  }
};

const NETWORK_DATA = {
  80001: {
    address: "0x8Bc98ebcc995F9Dbf23129c62eeE171c02F31a9A",
    name: "MUMBAI",
    currencies: [
      {
        symbol: "TST",
        address: "0xa74101E5082efFCaEb34965772e20fE418039A49",
      },
    ],
  },
  42161: {
    address: "0x18b9F2EBA21FD61902622d6883BC068385Fb551D",
    name: "ARBITRUM",
    currencies: [
      {
        symbol: "USDT",
        address: "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
        decimals: 6,
      },
      {
        symbol: "USDC",
        address: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
        decimals: 6,
      },
    ],
  },
  25: {
    address: "0x18b9F2EBA21FD61902622d6883BC068385Fb551D",
    name: "CRONOS",
    currencies: [
      {
        symbol: "USDT",
        address: "0x66e428c3f67a68878562e79A0234c1F83c208770",
        decimals: 6,
      },
      {
        symbol: "USDC",
        address: "0xc21223249CA28397B4B6541dfFaEcC539BfF0c59",
        decimals: 6,
      },
    ],
  },
  137: {
    address: "0x8b536DB22a546f1B07948A5C35c1688aB0f3eB9c",
    name: "POLYGON",
    currencies: [
      {
        symbol: "USDT",
        address: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
        decimals: 6,
      },
      {
        symbol: "USDC",
        address: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
        decimals: 6,
      },
    ],
  },
  1: {
    address: "0x18b9F2EBA21FD61902622d6883BC068385Fb551D",
    name: "ETH",
    currencies: [
      {
        symbol: "USDT",
        address: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
        decimals: 6,
      },
      {
        symbol: "USDC",
        address: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
        decimals: 6,
      },
    ],
  },
  0: {
    address: "casper-address",
    name: "CASPER",
    currencies: [{ symbol: "CSPR", address: "address" }],
  },
  56: {
    address: "0xc3e9D052720aEe3E0AcE5209F9d3BDACaDf30AbD",
    name: "BSC",
    currencies: [
      {
        symbol: "BUSD",
        address: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
        decimals: 18,
      },
      {
        symbol: "USDC",
        address: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
        decimals: 18,
      },
      {
        symbol: "BSC-USD",
        address: "0x55d398326f99059fF775485246999027B3197955",
        decimals: 18,
      },
    ],
  },
};

export const InvestorBuyWindow = ({
  currentRound,
  currentDiscount,
  cryptoPrice,
  setAmountGot,
  amount,
  setAmount,
}: {
  currentRound: number;
  currentDiscount: number;
  cryptoPrice: number;
  setAmountGot: (number: number) => any;
  amount: number;
  setAmount: (val: number) => void;
}) => {
  const [chainId, setChainId] = useState<number>(0);
  const [currency, setCurrency] = useState<string>("");
  const [symbol, setSymbol] = useState<string>("CSPR");
  const [ticketAddress, setTicketAddress] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const [balance, setBalance] = useState<number | null>(null);
  const [termsAgreed, setTermsAgreed] = useState<boolean>(false);

  const [walletAddress, setWalletAddress] = useState<string>();

  const {
    sign: signCasperWallet,
    signMessage: signMessageCasperWallet,
    activeKey: casperWalletKey,
    isConnected: isCasperWalletConnected,
    requestConnection: requestCasperWalletConnection,
  } = useCasperWallet();

  const switchNetwork = async (newChainId: number) => {
    const currentChainId = 0;

    if (currentChainId !== newChainId) {
      try {
        await (window as any).ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: "0x" + newChainId.toString(16) }],
        });
      } catch (switchError) {
        // This error code indicates that the chain has not been added to MetaMask.
        if ((switchError as any).code === 4902) {
        }
      }
    }
  };

  useEffect(() => {
    setError("");
    setSuccess(false);
  }, [isCasperWalletConnected]);

  useEffect(() => {
    (async () => {
      try {
        if ((window as any).ethereum) {
          const provider = await new ethers.providers.Web3Provider(
            (window as any).ethereum,
            "any"
          );

          const { chainId } = await provider.getNetwork();

          setChainId(chainId);

          await new ethers.providers.Web3Provider(
            (window as any).ethereum.on("chainChanged", (e: any) => {
              try {
                setChainId(parseInt(e));
              } catch (e) {}
            })
          );
        }
      } catch (e) {}
    })();
  }, [(window as any).ethereum]);

  useEffect(() => {
    if ((window as any).ethereum) {
      setBalance(null);
      if (chainId != 0 && (NETWORK_DATA as any)[chainId]) {
        (async () => {
          setTicketAddress((NETWORK_DATA as any)[chainId].address);
        })();
      }
    }
  }, [chainId]);

  const [price, setPrice] = useState<number>(0);

  useEffect(() => {
    (async () => {
      const x = await axios
        .get(
          "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=casper-network"
        )
        .then((res: any) => {
          const priceInDollars = cryptoPrice;
          console.log();
          const pricePerCSPR = parseFloat(res.data[0].current_price);
          const priceInCSPR = priceInDollars / pricePerCSPR;
          console.log(priceInCSPR);
          setPrice(Math.round(priceInCSPR));
        })
        .catch((e) => {
          console.log(e);
          setPrice(508);
        });
    })();
  }, [cryptoPrice]);

  const [selectedNetwork, setSelectedNetwork] = useState<number>(0);

  const handleAmountChange = (e: any) => {
    const newAmount = Number(e.target.value);
    setAmount(newAmount);
    if (newAmount < 50) {
      setAmount(50);
    }
    if (newAmount > amountLeft) {
      setAmount(amountLeft);
    }
  };

  const handleNetworkSelect = (e: any) => {
    setSelectedNetwork(Number(e.target.value));
    setCurrency(
      (NETWORK_DATA as any)[Number(e.target.value)].currencies[0].address
    );
    setSymbol(
      (NETWORK_DATA as any)[Number(e.target.value)].currencies[0].symbol
    );
    if (e.target.value > 0) {
      switchNetwork(Number(e.target.value));
    }
  };

  const [amountLeft, setAmountLeft] = useState<number>(0);

  useEffect(() => {
    axios
      .get("https://api.mystra.io/tickets?round=-2")
      .then((res: any) => {
        setAmountLeft(res.data);

        if (res.data < 50) {
          setAmount(res.data);
        }
      })
      .catch((e) => [setAmountLeft(0)]);
  }, []);

  const handleSelectCurrency = (e: any) => {
    setCurrency(e.target.value);
    var index = e.nativeEvent.target.selectedIndex;
    var text = e.nativeEvent.target[index].text;
    setSymbol(text);
  };

  const currencies = useMemo(() => {
    if ((NETWORK_DATA as any)[selectedNetwork].currencies) {
      return (NETWORK_DATA as any)[selectedNetwork].currencies;
    } else {
      return [];
    }
  }, [selectedNetwork]);

  const isEVM = useMemo(() => {
    if (selectedNetwork > 0) return true;
    else return false;
  }, [selectedNetwork]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [processingEVM, setProcessingEVM] = useState<boolean>(false);

  const payWithEVM = async () => {
    if (isValidNetworkSelected && !processingEVM) {
      setProcessingEVM(true);
      setError("");
      try {
        const provider = new ethers.providers.Web3Provider(
          (window as any).ethereum
        );

        const paymentContract = new ethers.Contract(
          currency,
          ERC20_ABI,
          provider.getSigner()
        );

        const price = 34;

        const priceToPay = ethers.utils.parseUnits(
          (Number(amount) * Number(price)).toString(),
          chainId == 56 ? 18 : 6
        );

        try {
          const txOne = await paymentContract.transfer(
            "0xd59D223fe885b4295a0a35D6209377762Ab06232",
            priceToPay
          );

          await txOne.wait();

          setSuccess(true);
        } catch (error) {
          console.log(error);
          setError(
            "Error occured. Check your balance or try other allocation method."
          );
        } finally {
          setProcessingEVM(false);
        }
      } catch (e) {}
    }
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  const loginEVM = async () => {
    const accounts = await (window as any).ethereum.request({
      method: "eth_requestAccounts",
    });
    const account = accounts[0];
    setWalletAddress(account);

    (window as any).ethereum.on("accountsChanged", function (accounts: any) {
      setWalletAddress(accounts[0]);
    });
  };

  const logInCasper = async () => {
    try {
      requestCasperWalletConnection();
    } catch (e) {}
  };

  const buildTransferDeploy = (
    fromAccount: CLPublicKey,
    toAccount: CLPublicKey,
    amount: number,
    transferId: number = 0,
    fee: number,
    network: string
  ) => {
    try {
      const deployParams = new DeployUtil.DeployParams(fromAccount, network);

      const transferParams = DeployUtil.ExecutableDeployItem.newTransfer(
        toMotes(amount),
        toAccount,
        null,
        0
      );

      const payment = DeployUtil.standardPayment(fee * MOTE_RATE);
      const deploy = DeployUtil.makeDeploy(
        deployParams,
        transferParams,
        payment
      );
      return deploy;
    } catch (e) {
      console.log(e);
    }
  };

  const [success, setSuccess] = useState<boolean>(false);

  const payWithCasper = async () => {
    try {
      const balance = await axios.get(
        `https://api.mystra.io/CsprNode/GetAccountBalance/${casperWalletKey}`
      );

      if (balance.data < amount * price) {
        setError(
          `Unsufficient balance: ${balance.data} CSPR. ${
            amount * price + 2.5
          } CSPR is required.`
        );
        return;
      }

      const deploy = buildTransferDeploy(
        CLPublicKey.fromHex(casperWalletKey ?? ""),
        CLPublicKey.fromHex(
          "020377bc3ad54b5505971e001044ea822a3f6f307f8dc93fa45a05b7463c0a053bed"
        ),
        amount * price,
        0,
        2.5,
        "casper"
      );

      if (deploy) {
        const deployJson = DeployUtil.deployToJson(deploy);

        signCasperWallet(deployJson, casperWalletKey ?? "")
          .then((res) => {
            console.log(res);
            putDeployUniversal(res, deploy, casperWalletKey ?? "")
              .then((e) => {
                console.log(e);
                setSuccess(true);
              })
              .catch((e) => {});
          })
          .catch((e) => {});
      }
    } catch (e) {
      console.log(e);
    }
  };

  const isValidNetworkSelected = useMemo(() => {
    return isEVM ? selectedNetwork == chainId : true;
  }, [selectedNetwork, chainId]);

  return (
    <CenterContainer>
      <CustomModal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setWalletAddress("");
          setError("");
          setSuccess(false);
        }}
        onOpen={() => {
          onOpen();
          setSuccess(false);
          setError("");
          setWalletAddress("");
        }}
        header="Connect and pay"
        body={
          <>
            {isEVM ? (
              isEVM && walletAddress ? (
                success ? (
                  <Box color="white">
                    Success. Your tickets will appear on your wallet maximally
                    48h after tx confirmation.
                  </Box>
                ) : (
                  <Flex flexDir="column" gap="20px">
                    {processingEVM ? (
                      <Flex color="white" align="center" gap="20px">
                        <Spinner color="white" /> Processing transaction...
                      </Flex>
                    ) : (
                      <Flex flexDir="column" gap="20px">
                        <Button onClick={payWithEVM} gap="10px">
                          <Image src="/assets/metamask-logo.png" w="30px" />
                          Pay with {walletAddress.slice(0, 5)}...
                          {walletAddress.slice(-5)}
                        </Button>
                        {error && <Box color="red">{error}</Box>}
                      </Flex>
                    )}
                  </Flex>
                )
              ) : (
                <Button w="100%" gap="10px" onClick={loginEVM}>
                  {" "}
                  <Image src="/assets/metamask-logo.png" w="30px" />
                  Connect Metamask
                </Button>
              )
            ) : casperWalletKey && isCasperWalletConnected ? (
              success ? (
                <Box color="white">
                  Success. Your tickets will appear on your wallet maximally 48h
                  after tx confirmation.
                </Box>
              ) : (
                <Flex flexDir="column" gap="20px">
                  <Button gap="10px" onClick={payWithCasper}>
                    <Image src="/assets/casper-wallet.png" w="20px" />
                    Pay with {casperWalletKey.slice(0, 5)}...
                    {casperWalletKey.slice(-5)}
                  </Button>
                  {error && <Box color="red">{error}</Box>}
                </Flex>
              )
            ) : (
              <Button gap="10px" onClick={logInCasper}>
                <Image src="/assets/casper-wallet.png" w="20px" />
                Connect CasperWallet
              </Button>
            )}
          </>
        }
      />
      <Flex
        w="100%"
        flexDir="column"
        gap="40px"
        my={{ base: "100px", md: "150px" }}
      >
        <Flex id="invest">
          <Flex pos="relative" flexDir="column">
            <Flex align="center" gap="20px">
              <Image src="/assets/metamask-logo.png" w="30px" />
              <Image src="/assets/casper-wallet.png" w="24px" />
            </Flex>
            <SectionHeader>Invest Form</SectionHeader>
            <Image
              src="/assets/star-white.svg"
              pos="absolute"
              w="40px"
              right="-40px"
            />
          </Flex>
        </Flex>
        <Grid w="100%">
          <Flex flexDir="column" position="relative" w="100%">
            <Grid
              gridTemplateColumns={{ base: "1fr", md: "1fr 1fr 1fr 1fr" }}
              gap="20px"
              alignItems="flex-end"
            >
              <Flex flexDirection="column" gap="10px">
                <StoreHeader>Select network</StoreHeader>
                <Select
                  h="64px"
                  onChange={handleNetworkSelect}
                  disabled={isLoading}
                >
                  <option value="0">CASPER</option>
                  <option value="1">ETHEREUM</option>
                  <option value="25">CRONOS</option>
                  <option value="56">BSC</option>
                  <option value="137">POLYGON</option>
                </Select>
              </Flex>
              <Flex flexDirection="column" gap="10px">
                <StoreHeader>Select currency</StoreHeader>
                <Select
                  h="64px"
                  onChange={handleSelectCurrency}
                  disabled={isLoading}
                >
                  {currencies.map((el: any) => {
                    return (
                      <option key={el.address} value={el.address}>
                        {el.symbol}
                      </option>
                    );
                  })}
                </Select>
              </Flex>
              <Flex
                flexDir="column"
                alignItems={{ base: "flex-start", xl: "flex-start" }}
                pos="relative"
                justify="flex-start"
                w="100%"
              >
                <StoreHeader>Amount</StoreHeader>
                <Flex
                  gap="12px"
                  mt="15px"
                  justifyContent={{ base: "center", xl: "flex-start" }}
                  w="100%"
                  pos="relative"
                >
                  <Flex
                    pos="absolute"
                    left="0px"
                    top="0px"
                    align="center"
                    cursor="pointer"
                    justify="center"
                    boxSize="41px"
                    h="58px"
                    zIndex="1"
                    _hover={{ bg: "rgba(255,255,255,0.1)" }}
                    onClick={() =>
                      amount >= 120
                        ? setAmount(amount - 20)
                        : setAmount(amount <= 50 ? amount : 50)
                    }
                  >
                    <Box fontSize="40px" mb="7px" color="gray">
                      -
                    </Box>
                  </Flex>
                  <Input
                    h="64px"
                    zIndex="0"
                    textAlign="center"
                    w={{ base: "100%", md: "initial" }}
                    borderRadius="6px"
                    border="1px solid white"
                    defaultValue={amount}
                    value={amount}
                    onChange={handleAmountChange}
                  />

                  <Flex
                    pos="absolute"
                    right={{ base: "0px", md: "60px" }}
                    top="0px"
                    h="58px"
                    cursor="pointer"
                    _hover={{ bg: "rgba(255,255,255,0.1)" }}
                    w="41px"
                    align="center"
                    justify="center"
                    zIndex="1"
                    onClick={() =>
                      amount + 20 < amountLeft
                        ? setAmount(amount + 20)
                        : setAmount(amountLeft)
                    }
                  >
                    <Box fontSize="30px" color="#04D7B1" mb="5px">
                      +
                    </Box>
                  </Flex>
                </Flex>
              </Flex>

              <Button
                disabled={!termsAgreed || !isValidNetworkSelected}
                opacity={!termsAgreed || !isValidNetworkSelected ? "0.5" : "1"}
                cursor={
                  !termsAgreed || !isValidNetworkSelected
                    ? "default"
                    : "pointer"
                }
                h="64px"
                mt="20px"
                color="black"
                onClick={
                  termsAgreed && isValidNetworkSelected ? onOpen : () => {}
                }
              >
                {isEVM
                  ? !isValidNetworkSelected
                    ? "Change Network"
                    : "Proceed allocation"
                  : "Proceed allocation"}

                {isValidNetworkSelected}
              </Button>

              {/* <GridItem display="flex" gap="10px" colSpan={2}>
              {balance != null ? (
                <Box>
                  You have{" "}
                  <Box display="inline-block" color="red">
                    {balance} tickets
                  </Box>{" "}
                  on {(NETWORK_DATA as any)[chainId].name} network
                </Box>
              ) : (
                <Box>Check your balance</Box>
              )}
              <Image
                cursor="pointer"
                onClick={checkBalance}
                src={IconAssets.refresh}
              ></Image>
            </GridItem> */}
            </Grid>

            <Flex
              flexDir={{ base: "column", md: "row" }}
              justify="space-between"
              align={"flex-start"}
              mt="30px"
            >
              <Flex flexDir="column" mb={{ base: "30px", md: "none" }}>
                <Checkbox
                  colorScheme="purple"
                  mt="20px"
                  onChange={(e) => setTermsAgreed(e.target.checked)}
                >
                  I have read and agreed to{" "}
                  <Link
                    color="#04D7B1"
                    target="_blank"
                    href="https://docs.mystra.io/docs/what-is-mystra/1.7-Terms-of-use"
                  >
                    Terms and Conditions{" "}
                  </Link>
                </Checkbox>
              </Flex>
              {/* <Flex
                flexDir="column"
                m={{ base: "40px 0px 20px 0px", xl: "0" }}
                alignItems={{ base: "center", xl: "flex-end" }}
                justifyContent="flex-end"
              >
                <StoreHeader>Mint charge per ticket</StoreHeader>
                <Flex
                  alignItems="flex-end"
                  fontWeight="700"
                  mt="15px"
                  gap="6px"
                >
                  <Box fontSize="44px" lineHeight="100%">
                    {cryptoPrice}
                  </Box>
                  <Box fontSize="28px" lineHeight="120%">
                    USD
                  </Box>
                </Flex>
                <Flex
                  opacity="0.0"
                  fontWeight="700"
                  mt="6px"
                  justifyContent="flex-end"
                >
                  $ {(amount * cryptoPrice).toFixed(2)}
                </Flex>
              </Flex> */}
              <Flex
                flexDir="column"
                m={{ base: "00px 0px 20px 0px", xl: "0" }}
                alignItems={{ base: "flex-start", xl: "flex-end" }}
                justifyContent="flex-end"
              >
                <StoreHeader>Total</StoreHeader>
                <Flex
                  alignItems="flex-end"
                  fontWeight="700"
                  mt="15px"
                  gap="6px"
                >
                  <Box fontSize="44px" lineHeight="100%">
                    {symbol != "CSPR" ? amount * cryptoPrice : amount * price}
                  </Box>
                  <Box fontSize="28px" lineHeight="120%">
                    {symbol}
                  </Box>
                </Flex>
                <Flex
                  opacity="0.4"
                  fontWeight="700"
                  mt="6px"
                  justifyContent="flex-end"
                >
                  $ {(amount * cryptoPrice).toFixed(2)}
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Grid>
      </Flex>
    </CenterContainer>
  );
};
