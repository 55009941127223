import {
  Box,
  Button,
  Checkbox,
  Flex,
  Grid,
  HStack,
  Image,
  Input,
  Link,
} from "@chakra-ui/react";
import axios from "axios";
import { useEffect, useState } from "react";
import { CenterContainer } from "../shared/containers/CenterContainer";
import { PinInput, PinInputField } from "@chakra-ui/react";

export const SaleCountdown = () => {
  const [checked, setChecked] = useState<boolean>(false);

  const [success, setSuccess] = useState<boolean>(false);

  const [email, setEmail] = useState<string>("");

  const [emailError, setEmailError] = useState<string | null>(null);

  const [loading, setLoading] = useState<boolean>(false);

  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const [target, setTarget] = useState("December, 1, 2023");

  const [finished, setFinished] = useState<boolean>(false);

  // useEffect(() => {
  //   const time = Date.parse(target) - Date.now();

  //   setFinished(time <= 0);
  // });

  const getTime = (deadline: string) => {
    const time = Date.parse(deadline) - Date.now();

    setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
    setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
    setMinutes(Math.floor((time / 1000 / 60) % 60));
    setSeconds(Math.floor((time / 1000) % 60));
  };

  useEffect(() => {
    const interval = setInterval(() => getTime(target), 1000);

    return () => clearInterval(interval);
  }, []);

  const handleSend = () => {
    setEmailError(null);
    let regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regEmail.test(email)) {
      setEmailError("Invalid email");
      return;
    }
    axios
      .post(`https://api.mystra.io/Newsletter`, {
        email: email,
        conditionsAccepted: true,
      })
      .then((e: any) => {
        (window as any).dataLayer.push({
          event: "newsletter_submit",
        });

        setSuccess(true);
        setEmail("");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const [code, setCode] = useState<string>("");
  const [codeError, setCodeError] = useState<string | null>(null);

  const handleCode = () => {
    setCodeError("Code provided is not valid");
  };

  return (
    <CenterContainer>
      <Flex pos="relative" w="100%">
        <Image
          pos="absolute"
          w="100%"
          height="100%"
          opacity="0.5"
          left="0"
          bottom="0"
          top="20%"
          zIndex={0}
          right="0"
          margin="auto"
          src="/assets/elements/sale-blur.png"
        />

        <Flex
          mt={{ base: "none", md: "150px" }}
          py="112px"
          border="1px solid"
          borderColor={{ base: "transparent", md: "#1c1c1c" }}
          borderRadius="60px"
          align="center"
          justify="center"
          w="100%"
          pos="relative"
          bg={"rgba(0,0,0,0.2)"}
          textAlign="center"
          flexDir="column"
          backdropFilter="blur(20px)"
          zIndex={0}
          gap="40px"
        >
          {/* <Flex
            fontSize="25px"
            align="center"
            gap="7px"
            color="#04D7B1"
            mb="-20px"
            fontFamily="Plus Jakarta Sans"
            fontWeight="700"
          >
            Time limited <Image src="/assets/clock.svg" />
          </Flex> */}
          <Flex
            fontFamily="Inter Tight"
            fontWeight="bold"
            fontSize={{ base: "40px", md: "70px" }}
            lineHeight={{ base: "48px", md: "78px" }}
            maxW="800px"
          >
            {finished
              ? "Join the waitlist"
              : "Premium Pass minting will appear here"}
          </Flex>
          <Box
            color="white"
            fontFamily={"Plus Jakarta Sans"}
            fontSize="21px"
            maxW="650px"
          >
            {finished
              ? "MystraCode do waitlist zostały wysłane do osób zapisanych do newlsettera. Chcesz się podzielić invitem? Podziel się nim na X (Twitter) pod hashategiem #mystracode"
              : "The quantity of available Premium Passes is limited in each round Premium Pass value on the platform increases as new rounds open"}
          </Box>

          {finished && (
            <Flex align="center" gap="20px" fontFamily="Inter" fontSize="70px">
              <Grid
                justifyContent="center"
                templateColumns={"80px auto 80px auto 80px auto 80px"}
                gap="40px"
              >
                <Flex flexDir="column" align="center" gap="10px">
                  <Flex lineHeight="100%" fontFamily={"Plus Jakarta Sans"}>
                    {days}
                  </Flex>
                  <Flex
                    fontSize="14px"
                    textTransform="uppercase"
                    color="#04D7B1"
                    letterSpacing="8px"
                    fontFamily="Plus Jakarta Sans"
                    fontWeight="Bold"
                  >
                    Days
                  </Flex>
                </Flex>
                <Flex flexDir="column" align="center">
                  <Flex
                    lineHeight="130%"
                    fontSize="50px"
                    fontFamily={"Inter Tight"}
                  >
                    :
                  </Flex>
                  <Flex
                    fontSize="13.5px"
                    textTransform="uppercase"
                    color="#04D7B1"
                    fontFamily="Plus Jakarta Sans"
                  ></Flex>
                </Flex>

                <Flex flexDir="column" align="center" gap="10px">
                  <Flex lineHeight="100%" fontFamily={"Plus Jakarta Sans"}>
                    {hours}
                  </Flex>
                  <Flex
                    fontSize="14px"
                    textTransform="uppercase"
                    color="#04D7B1"
                    fontFamily="Plus Jakarta Sans"
                    letterSpacing="8px"
                    fontWeight="Bold"
                  >
                    Hours
                  </Flex>
                </Flex>
                <Flex flexDir="column" align="center">
                  <Flex
                    lineHeight="130%"
                    fontSize="50px"
                    fontFamily={"Inter Tight"}
                  >
                    :
                  </Flex>
                  <Flex
                    fontSize="14px"
                    textTransform="uppercase"
                    color="#04D7B1"
                    fontFamily="Plus Jakarta Sans"
                  ></Flex>
                </Flex>
                <Flex flexDir="column" align="center" gap="10px">
                  <Flex lineHeight="100%" fontFamily="Inter Tight">
                    {minutes}
                  </Flex>
                  <Flex
                    fontSize="14px"
                    textTransform="uppercase"
                    color="#04D7B1"
                    fontFamily="Plus Jakarta Sans"
                    fontWeight="Bold"
                    letterSpacing="8px"
                  >
                    Minutes
                  </Flex>
                </Flex>
                <Flex flexDir="column" align="center">
                  <Flex
                    lineHeight="130%"
                    fontSize="50px"
                    fontFamily={"Inter Tight"}
                  >
                    :
                  </Flex>
                  <Flex
                    fontSize="14px"
                    textTransform="uppercase"
                    color="#04D7B1"
                    fontFamily="Plus Jakarta Sans"
                  ></Flex>
                </Flex>
                <Flex flexDir="column" align="center" gap="10px">
                  <Flex lineHeight="100%" fontFamily="Inter Tight">
                    {seconds}
                  </Flex>
                  <Flex
                    fontSize="14px"
                    textTransform="uppercase"
                    color="#04D7B1"
                    fontFamily="Plus Jakarta Sans"
                    fontWeight="Bold"
                    letterSpacing="8px"
                  >
                    Seconds
                  </Flex>
                </Flex>
              </Grid>
            </Flex>
          )}
          {finished && (
            <Flex flexDir="column" align="center">
              <Flex mb="40px" flexDir="column" h="40px" maxW="500px">
                <Grid
                  templateColumns={"1fr 1fr 1fr 1fr 1fr 1fr"}
                  gap="20px"
                  h="100px"
                >
                  <PinInput
                    type="alphanumeric"
                    size="xl"
                    value={code}
                    onChange={(e) => {
                      setCode(e);
                      setCodeError(null);
                    }}
                  >
                    <PinInputField
                      borderColor={codeError ? "#FF2D53" : "#00FFD1"}
                      height={"70px"}
                      bg="#111"
                      borderRadius="8px"
                    />
                    <PinInputField
                      borderColor={codeError ? "#FF2D53" : "#00FFD1"}
                      height={"70px"}
                      bg="#111"
                      borderRadius="8px"
                    />
                    <PinInputField
                      borderColor={codeError ? "#FF2D53" : "#00FFD1"}
                      height={"70px"}
                      bg="#111"
                      borderRadius="8px"
                    />
                    <PinInputField
                      borderColor={codeError ? "#FF2D53" : "#00FFD1"}
                      height={"70px"}
                      bg="#111"
                      borderRadius="8px"
                    />
                    <PinInputField
                      borderColor={codeError ? "#FF2D53" : "#00FFD1"}
                      height={"70px"}
                      bg="#111"
                      borderRadius="8px"
                    />
                    <PinInputField
                      borderColor={codeError ? "#FF2D53" : "#00FFD1"}
                      height={"70px"}
                      bg="#111"
                      borderRadius="8px"
                    />
                  </PinInput>
                </Grid>
              </Flex>

              <Button
                background="none"
                mt={{ base: "20px", md: "none" }}
                sx={{
                  fontSize: "20px",
                  color: "white",
                  border: "1px solid #04D7B1",
                  fontFamily: "DM Sans",
                  fontWeight: "bold",
                  borderRadius: "18px",
                  height: "76px",
                  paddingX: "40px",
                  gap: "40px",
                }}
                onClick={handleCode}
                _hover={{
                  background: "rgba(255,255,255,0.1)",
                }}
              >
                Let me in now!
                <Image
                  src="/assets/arrow-right-brand.svg"
                  alt="Brand arrow"
                  width="32px"
                  height="32px"
                />
              </Button>
              <Flex flexDir="column" mt="10px" h="30px" justify="center">
                {codeError && (
                  <Box
                    fontFamily="Plus Jakarta Sans"
                    color="#FF2D53"
                    fontSize="16px"
                    fontWeight="700"
                  >
                    The code provided is not valid
                  </Box>
                )}
              </Flex>
              <Flex mt="20px" fontSize="18px" fontFamily="Plus Jakarta Sans">
                Already joined? &nbsp;
                <Box
                  cursor="pointer"
                  color="#04D7B1"
                  fontWeight="bold"
                  display="inline"
                >
                  Connect wallet to log in
                </Box>
              </Flex>
            </Flex>
          )}
          {!finished && (
            <>
              <Box
                fontFamily={"Plus Jakarta Sans"}
                lineHeight="29px"
                fontSize="28px"
                fontWeight="bold"
                maxW="650px"
              >
                Don’t miss it out!
                <br /> Sign up and be ahead of others
              </Box>
              <Flex flexDir="column" gap="16px">
                <Flex pos="relative">
                  <Input
                    bg="#151515"
                    borderColor={emailError ? "red" : "#2E2E2E"}
                    h="60px"
                    borderRadius="30px"
                    value={email}
                    type="email"
                    w={{ base: "80vw", md: "100vw" }}
                    px="40px"
                    maxW="510px"
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setEmailError(null);
                      setSuccess(false);
                    }}
                  />
                  <Flex
                    pos="absolute"
                    cursor={checked ? "pointer" : "default"}
                    boxSize="40px"
                    zIndex="2"
                    filter={checked ? "none" : "grayscale(1)"}
                    bg="#151515"
                    onClick={checked ? handleSend : () => {}}
                    opacity={checked ? "1" : "0.5"}
                    align="center"
                    justify="center"
                    top="0"
                    bottom="0"
                    right="20px"
                    margin="auto"
                  >
                    <Image src="/assets/arrow-right-brand.svg" />
                  </Flex>
                </Flex>
                {success && (
                  <Box
                    padding="10px"
                    bg="#04D7B1"
                    color="white"
                    fontSize="20px"
                    borderRadius="8px"
                    fontWeight="bold"
                  >
                    Successfully Joined newsletter!
                  </Box>
                )}
                {emailError && <Box color="red">Invalid email provided!</Box>}

                <Flex
                  align="center"
                  flexDir={{ base: "column", md: "row" }}
                  gap="20px"
                  justify="space-between"
                >
                  <Checkbox
                    checked={checked}
                    onChange={(e) => {
                      setChecked(e.target.checked);
                    }}
                  >
                    I accept marketing terms.
                  </Checkbox>
                  <Link href="https://twitter.com/mystra_io">
                    <Flex align="center" gap="10px">
                      Or follow us on <Image src="/assets/x-logo.svg" />
                    </Flex>
                  </Link>
                </Flex>
              </Flex>
            </>
          )}
        </Flex>
      </Flex>
    </CenterContainer>
  );
};
