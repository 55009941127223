import { Box, Flex, Image, Link } from "@chakra-ui/react";
import { CenterContainer } from "../shared/containers/CenterContainer";
import { DottedLabel } from "../shared/typography/DottedLabel";

type Member = {
  image: string;
  name: string;
  role: string;
  linkedin?: string;
};

// {
//   name: "Piotr Dziubecki",
//   role: "Advisors",
//   linkedin: "https://www.linkedin.com/in/piotrdziubecki/",
// },
// {
//   name: "Jakub Jaworski",
//   role: "Advisors",
//   linkedin: "https://www.linkedin.com/in/jjaworskii/",
// },

const members: Member[] = [
  {
    image: "kamil-szymoniak.png",
    role: "Co-Founder, CEO & Backend Developer",
    name: "Kamil Szymoniak",
    linkedin: "https://www.linkedin.com/in/kamil-szymoniak/",
  },
  {
    image: "wojciech-luby.png",
    role: "Co-Founder & Business Development Manager",
    name: "Wojciech Luby",
    linkedin: "https://www.linkedin.com/in/wojciech-luby/",
  },
  {
    image: "radek-zaleski.png",
    role: "Co-Founder, Product & Strategy Manager",
    name: "Radek Zaleski",
    linkedin:
      "https://www.linkedin.com/in/radoslaw-zaleski-562271a3?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
  },
  {
    image: "tomasz-fiema.png",
    role: "Co-Founder,  UX/UI Specialist",
    name: "Tomasz Fiema",
    linkedin: "https://www.linkedin.com/in/tomaszfiema/",
  },
  {
    image: "damian-sarnecki.png",
    role: "Co-Founder, Fullstack Developer",
    name: "Damian Sarnecki",
    linkedin: "https://www.linkedin.com/in/damiansarnecki/",
  },

  {
    image: "sebastian-strzalkowski.png",
    role: "Backend Developer",
    name: "Sebastian",
    linkedin: "https://www.linkedin.com/in/sebastianstrzalkowski/",
  },

  {
    image: "adrian-p.png",
    role: "Servers & Security Management",
    name: "Adrian",
  },
  {
    image: "martyna-szpil.png",
    role: "Digital Graphic Artist",
    name: "Martyna Szpil",
    linkedin: "https://www.linkedin.com/in/martyna-szpil-49bb48183/",
  },
  {
    image: "bradley-thomas.png",
    role: "Community Manager",
    name: "Bradley Thomas",
    linkedin: "https://www.linkedin.com/in/bradley-thomas-66469a9b/",
  },

  {
    image: "ricky.png",
    role: "Head of the Relaxation Department",
    name: "Ricky the Dog",
  },
  {
    image: "adrian-p.png",
    role: "Head of the relax department",
    name: "Ricky the Dog",
  },
  {
    image: "adrian-p.png",
    role: "Head of the relax department",
    name: "Ricky the Dog",
  },
];

export const Team = () => {
  return (
    <Flex overflowX="hidden" pos="relative">
      <Image
        pos="absolute"
        right="0px"
        display={{ base: "none", md: "flex" }}
        top="180px"
        src="/assets/team-decor-right.svg"
      />
      <Image
        pos="absolute"
        right="0px"
        w="1200px"
        display={{ base: "none", md: "flex" }}
        top="0"
        src="/assets/header-blur.png"
      />
      <CenterContainer>
        <Flex
          flexDir="column"
          mt={{ base: "0px", md: "140px" }}
          w="100%"
          pos="relative"
        >
          <Box pos="absolute" top="-100px" left="0" id="team" />

          <Flex pos="relative" flexDir="column" gap="20px">
            <DottedLabel>Meet the team</DottedLabel>

            <Flex
              fontSize={{ base: "40px", md: "70px" }}
              lineHeight={{ base: "48px", md: "78px" }}
              fontWeight="bold"
              fontFamily="Inter Tight"
              maxW="700px"
            >
              Our Team is made of active Web3 Users & Creators
            </Flex>
          </Flex>
          <Flex
            py="80px"
            rowGap={{ base: "70px", md: "90px" }}
            flexDir={{ base: "column", md: "row" }}
            flexWrap={"wrap"}
            align="center"
            justify="flex-start"
          >
            {members.map((member, index) => {
              return (
                <Flex
                  opacity={index > members.length - 3 ? "0" : "1"}
                  display={{
                    base: index > members.length - 3 ? "none" : "flex",
                    md: "flex",
                  }}
                  flex={{ base: "initial", md: "1 0 25%" }}
                  pos="relative"
                  flexDir={{
                    base: index % 2 == 0 ? "row-reverse" : "row",
                    md: "column",
                  }}
                  align={{ base: "center", md: "flex-start" }}
                  gap={{ base: "38px", md: "0px" }}
                  justifySelf={{
                    base: index % 2 == 0 ? "flex-start" : "flex-end",
                    md: "flex-start",
                  }}
                  fontFamily="Plus Jakarta Sans"
                >
                  <Flex pos="relative" boxSize="116px">
                    <Image
                      boxSize="116px"
                      src={"/assets/team/" + member.image}
                    />
                    <Image
                      pos="absolute"
                      boxSize="116px"
                      right="5px"
                      top="5px"
                      src={"/assets/team/decor.svg"}
                    />
                    {member.linkedin && (
                      <Link href={member.linkedin}>
                        <Image
                          pos="absolute"
                          right="-34px"
                          boxSize="20px"
                          display={{ base: "none", md: "block" }}
                          bottom="10px"
                          src={"/assets/team/linkedin.svg"}
                        />
                      </Link>
                    )}
                  </Flex>
                  <Flex flexDir="column" align="flex-start">
                    <Box
                      mt={{ base: "none", md: "20px" }}
                      fontSize="21px"
                      fontWeight="bold"
                    >
                      {member.name}
                    </Box>
                    <Box
                      textAlign={{
                        md: "left",
                      }}
                      mt="16px"
                      maxW="180px"
                      fontSize="16px"
                      color="#A0A0A0"
                      minH={"44px"}
                    >
                      {member.role}
                    </Box>
                    {member.linkedin && (
                      <Link href={member.linkedin}>
                        <Image
                          h="20px"
                          mt="16px"
                          display={{ base: "block", md: "none" }}
                          src={"/assets/team/linkedin.svg"}
                        />
                      </Link>
                    )}
                  </Flex>
                </Flex>
              );
            })}
            <Flex
              flexDir="column"
              mt={{ base: "40px", md: "0px" }}
              borderRadius={"60px"}
              border={{ base: "none", md: "1px solid white" }}
              padding={{ base: "none", md: "38px 140px 38px 68px" }}
              pos="relative"
              transform={{ base: "none", md: "translateX(-68px)" }}
            >
              <Flex
                align="center"
                fontFamily="Inter Tight"
                fontWeight="bold"
                position={{ base: "relative", md: "absolute" }}
                mb={{ base: "40px", md: "none" }}
                top="0"
                bottom="0"
                right={{ base: "none", md: "-80px" }}
                margin={{ base: "0", md: "auto" }}
                fontSize={{ base: "27px", md: "27px" }}
              >
                <Box
                  mb={{ base: "40px", md: "0px" }}
                  bg="#090909"
                  lineHeight={{ base: "100%", md: "300%" }}
                >
                  Advisors
                </Box>
              </Flex>

              <Flex>
                <Flex
                  flexDir={{ base: "column", md: "row" }}
                  gap={{ base: "60px", md: "130px" }}
                >
                  <Flex
                    flex={{ base: "initial", md: "1 0 25%" }}
                    pos="relative"
                    flexDir={{
                      base: "row",
                      md: "column",
                    }}
                    align={{ base: "center", md: "flex-start" }}
                    gap={{ base: "38px", md: "0px" }}
                    justifySelf={{
                      base: "flex-start",
                      md: "flex-start",
                    }}
                    fontFamily="Plus Jakarta Sans"
                  >
                    <Flex pos="relative" boxSize="116px">
                      <Image
                        boxSize="116px"
                        src={"/assets/team/piotr-dziubecki.png"}
                      />
                      <Image
                        pos="absolute"
                        boxSize="116px"
                        right="5px"
                        top="5px"
                        src={"/assets/team/decor.svg"}
                      />

                      <Link
                        href={"https://www.linkedin.com/in/piotrdziubecki/"}
                      >
                        <Image
                          pos="absolute"
                          right="-34px"
                          boxSize="20px"
                          display={{ base: "none", md: "block" }}
                          bottom="10px"
                          src={"/assets/team/linkedin.svg"}
                        />
                      </Link>
                    </Flex>
                    <Flex flexDir="column" align="flex-start">
                      <Box
                        mt={{ base: "none", md: "20px" }}
                        fontSize="21px"
                        fontWeight="bold"
                      >
                        Piotr Dziubecki
                      </Box>
                      <Box
                        textAlign={{
                          md: "left",
                        }}
                        mt="16px"
                        maxW="180px"
                        fontSize="16px"
                        color="#A0A0A0"
                        minH={"44px"}
                      >
                        Casper Association
                      </Box>

                      <Link
                        href={"https://www.linkedin.com/in/piotrdziubecki/"}
                      >
                        <Image
                          h="20px"
                          mt="16px"
                          display={{ base: "block", md: "none" }}
                          src={"/assets/team/linkedin.svg"}
                        />
                      </Link>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </CenterContainer>
    </Flex>
  );
};
