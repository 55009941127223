import { Box, Image } from "@chakra-ui/react";
import { useWindowScroll } from "@uidotdev/usehooks";
import { useEffect, useState } from "react";

export const BackgroundAnimation = () => {
  const [scrollY, setScrollY] = useState<number>(0);

  const [communityX, setCommunityX] = useState<number>(0);
  const [creatorX, setCreatorX] = useState<number>(0);
  const [communityY, setCommunityY] = useState<number>(0);
  const [creatorY, setCreatorY] = useState<number>(0);

  const [creatorCircleSize, setCreatorCircleSize] = useState<number>(1);
  const [communityCircleSize, setCommunityCircleSize] = useState<number>(1);
  const [creatorVisibility, setCreatorVisibility] = useState<number>(0);
  const [communityVisibility, setCommunityVisibility] = useState<number>(0);
  const [creatorVisibilityTwo, setCreatorVisibilityTwo] = useState<number>(1);

  const [{ x, y }] = useWindowScroll();

  useEffect(() => {
    if (y)
      if (y < window.innerHeight + 138) {
        setScrollY(-1);
      } else {
        setScrollY((y - (window.innerHeight + 138)) / 600);
      }
  }, [y]);

  useEffect(() => {
    if (scrollY > -1 && scrollY <= 0) {
      setCommunityY(-scrollY * 0.2 + 0.55);
      setCreatorY(scrollY * 0.2 + 0.55);
      setCommunityX(0.75);
      setCreatorX(0.95);
      setCreatorVisibilityTwo(1);
      setCommunityVisibility(0);
      setCreatorVisibility(0);
    } else if (scrollY > 0 && scrollY <= 1) {
      setCreatorCircleSize(-scrollY * 0.8 + 1);

      setCommunityY(scrollY * 0.2 + 0.35);
      setCreatorY(-scrollY * 0.4 + 0.65);
      setCreatorVisibilityTwo(1);
      setCommunityX(-scrollY * 0.2 + 0.75);
      setCreatorX(-scrollY * 0.4 + 0.85);
      setCommunityVisibility(scrollY);
    } else if (scrollY >= 1 && scrollY < 2) {
      setCreatorCircleSize((scrollY - 1) * 0.8 + 0.2);
      setCreatorVisibility(scrollY - 1);
      setCommunityVisibility(-scrollY + 2);
      setCreatorVisibilityTwo(1);
      setCommunityCircleSize((-scrollY + 1) * 0.8 + 1);
      setCreatorY((scrollY - 1) * 0.2 + 0.25);
      setCommunityX((-scrollY + 1) * 0.4 + 0.55);
      setCommunityY((-scrollY + 1) * 0.3 + 0.55);
      setCreatorX((-scrollY + 1) * 0.2 + 0.45);
    } else if (scrollY >= 2 && scrollY <= 3) {
      setCreatorCircleSize((scrollY - 1) * 0.2 + 0.8);
      setCommunityVisibility(scrollY - 2);
      setCreatorVisibilityTwo(-scrollY + 3);

      setCommunityCircleSize((scrollY - 2) * 0.5 + 0.2);
      setCreatorY((scrollY - 2) * 0.05 + 0.45);
      setCommunityX((scrollY - 2) * 0.3 + 0.15);
      setCommunityY((scrollY - 1) * 0.2 + 0.1);
      setCreatorX((scrollY - 2) * 0.25 + 0.25);
    } else if (scrollY > 3 && scrollY < 4) {
      setCreatorCircleSize(1.2);
      setCommunityCircleSize(0.7);

      setCreatorX(0.5);
    } else if (scrollY > 3) {
      // setCreatorY((scrollY - 2) * 0.15 + 0.35);
      // setCommunityX((scrollY - 2) * 0.25 + 0.2);
      // setCommunityY((scrollY - 1) * 0.2 + 0.0);
      setCreatorX(0.5);
    } else {
      setCommunityX(0.75);
      setCreatorX(0.85);
      setCommunityY(0.35);
      setCreatorY(0.65);
    }
  }, [scrollY]);

  return (
    <Box
      sx={{
        width: "100vw",
        maxWidth: "1360px",
        position: "absolute",
        left: 0,

        right: 0,
        zIndex: "-1",
        margin: "auto",
        height: "2400px",
      }}
      display={{ base: "none", md: "block" }}
    >
      <Box
        sx={{
          position: "sticky",
          width: "100vw",
          maxWidth: "1360px",
          top: 0,

          bottom: 0,
          margin: "auto",
          display: "flex",
          height: "600px",

          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: "1360px",
            height: "600px",

            position: "relative",
          }}
        >
          <Box
            sx={{
              width: "1px",
              height: "1px",
              position: "absolute",

              left: 0,
              top: 0,

              margin: "auto",
            }}
            willChange={"transform"}
            transform={`translate(${1200 * communityX + 70 + "px"}, ${
              600 * communityY + "px"
            })`}
          >
            <Box
              sx={{
                position: "relative",
                height: "4px",
                width: "4px",
              }}
            >
              <Box
                className="communityPlanet"
                sx={{
                  width: `${230 * communityCircleSize}px`,

                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  margin: "auto",
                  height: `${230 * communityCircleSize}px`,

                  position: "absolute",
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    opacity: communityVisibility,
                  }}
                >
                  <Image
                    src="/assets/planet-community-left.svg"
                    alt="Planet"
                    objectFit="contain"
                  />
                </Box>
                <Box sx={{ position: "absolute" }}>
                  <Image
                    src="/assets/planet-community-right.svg"
                    alt="Planet"
                    objectFit="contain"
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: "1px",
              height: "1px",
              position: "absolute",
              left: 0,
              top: 0,

              margin: "auto",
            }}
            willChange={"transform"}
            transform={`translate(${1200 * creatorX + 70 + "px"}, ${
              600 * creatorY + 80 + "px"
            })`}
          >
            <Box
              sx={{
                position: "relative",
                height: "4px",
                width: "4px",
              }}
            >
              <Box
                className="creatorPlanet"
                sx={{
                  width: `${230 * creatorCircleSize}px`,

                  left: "50%",
                  transform: `translate(-50%, -50%)`,

                  margin: "auto",
                  height: `${230 * creatorCircleSize}px`,

                  position: "absolute",

                  borderRadius: "50%",
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    opacity: creatorVisibility,
                  }}
                >
                  <Image
                    src="/assets/planet-creator-right.svg"
                    alt="Planet"
                    objectFit="contain"
                  />
                </Box>
                <Box
                  sx={{
                    position: "absolute",
                    opacity: creatorVisibilityTwo,
                  }}
                >
                  <Image
                    src="/assets/planet-creator-left.svg"
                    alt="Planet"
                    objectFit="contain"
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
