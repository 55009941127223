export const ROUNDS = [
  {
    number: 1,
    price: 50,
    amount: 2000,
    x: 1.47,
  },
  {
    number: 2,
    price: 80,
    amount: 4500,
    x: 2.35,
  },
  {
    number: 3,
    price: 130,
    amount: 8500,
    x: 3.82,
  },
  {
    number: 4,
    price: 230,
    amount: 12500,
    x: 6.76,
  },
 
];
