import { Box, Flex, Grid } from "@chakra-ui/react";

export const InfoHeader = ({
  children,
  header,
}: {
  children: React.ReactNode;
  header: string;
}) => {
  return (
    <Flex flexDir="column" gap="40px">
      <Grid templateColumns="auto 1fr" gap="40px" alignItems="center">
        <Flex
          textTransform={"uppercase"}
          fontWeight="medium"
          fontFamily="Inter Tight"
          fontSize="30px"
        >
          {header}
        </Flex>
        <Box h="1px" bg="white" />
      </Grid>
      <Flex color="rgba(255,255,255,0.85)">{children}</Flex>
    </Flex>
  );
};
