import { Box, Flex, Grid, Image } from "@chakra-ui/react";
import { CenterContainer } from "../shared/containers/CenterContainer";

const Pointer = () => (
  <Box
    border="5px solid #091E1B"
    borderRadius="50%"
    boxSize="18px"
    bg="#04D7B1"
  />
);

export const SaleView = ({ amount }: { amount: number }) => {
  return (
    <CenterContainer>
      <Grid
        id="token"
        templateColumns={{ base: "1fr", md: "1fr 1fr" }}
        gap="50px"
      >
        <Flex align="center">
          <Image src="/assets/elements/exchange.png" />
        </Flex>
        <Flex justify="center" flexDir="column" gap="20px">
          <Flex gap="20px" align="center">
            <Pointer />
            <Flex flexDir="column" fontFamily="Plus Jakarta Sans">
              <Box fontSize="16px" lineHeight="24px">
                Mint charge per Premium Pass
              </Box>
              <Box
                color="#04D7B1"
                letterSpacing="0.08em"
                fontWeight="bold"
                textTransform="uppercase"
                fontSize="16px"
                lineHeight="24px"
              >
                34 USD
              </Box>
            </Flex>
          </Flex>
          <Flex gap="20px" align="center">
            <Pointer />
            <Flex flexDir="column" fontFamily="Plus Jakarta Sans">
              <Box fontSize="16px" lineHeight="24px">
                Min. allocation
              </Box>
              <Flex
                align="center"
                gap="10px"
                color="#04D7B1"
                letterSpacing="0.08em"
                fontWeight="bold"
                textTransform="uppercase"
                fontSize="16px"
                lineHeight="24px"
              >
                {1700} USD
                <Box
                  display="inline"
                  fontSize="12px"
                  color="gray"
                  fontWeight="normal"
                >
                  (min. 50 passes)
                </Box>
              </Flex>
            </Flex>
          </Flex>

          <Flex gap="20px" align="center">
            <Pointer />
            <Flex flexDir="column" fontFamily="Plus Jakarta Sans">
              <Box fontSize="16px" lineHeight="24px">
                Cliff after TGE
              </Box>
              <Box
                color="#04D7B1"
                letterSpacing="0.08em"
                fontWeight="bold"
                fontSize="16px"
                lineHeight="24px"
              >
                2 months
              </Box>
            </Flex>
            <Flex flexDir="column" fontFamily="Plus Jakarta Sans">
              <Box fontSize="16px" lineHeight="24px">
                Ability to exchange 100% NFT allocation
              </Box>
              <Box
                color="#04D7B1"
                letterSpacing="0.08em"
                fontWeight="bold"
                fontSize="16px"
                lineHeight="24px"
              >
                10 months vesting
              </Box>
            </Flex>
          </Flex>
          <Flex gap="20px" align="center">
            <Pointer />
            <Flex flexDir="column" gap="8px" fontFamily="Plus Jakarta Sans">
              <Flex fontFamily="Plus Jakarta Sans" gap="10px">
                <Box fontSize="16px" lineHeight="24px">
                  Exchange release every month
                </Box>
                <Box
                  color="#04D7B1"
                  letterSpacing="0.08em"
                  fontWeight="bold"
                  fontSize="16px"
                  lineHeight="24px"
                >
                  10%
                </Box>
              </Flex>
              <Flex fontFamily="Plus Jakarta Sans" gap="10px">
                <Box fontSize="16px" lineHeight="24px">
                  Exchange release per month
                </Box>
                <Box
                  color="#04D7B1"
                  letterSpacing="0.08em"
                  fontWeight="bold"
                  fontSize="16px"
                  lineHeight="24px"
                >
                  Passes: {Math.ceil(amount * 0.1)}
                </Box>
              </Flex>
              <Flex fontFamily="Plus Jakarta Sans" gap="10px">
                <Box fontSize="16px" lineHeight="24px">
                  Full exchange release after TGE
                </Box>
                <Box
                  color="#04D7B1"
                  letterSpacing="0.08em"
                  fontWeight="bold"
                  fontSize="16px"
                  lineHeight="24px"
                >
                  12 months
                </Box>
              </Flex>
              <Flex fontFamily="Plus Jakarta Sans" gap="10px">
                <Box fontSize={{ base: "14px", md: "16px" }} lineHeight="24px">
                  Premium Pass Release
                </Box>
                <Box
                  color="#04D7B1"
                  letterSpacing="0.08em"
                  fontWeight="bold"
                  fontSize="16px"
                  lineHeight="24px"
                >
                  48h from mint
                </Box>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Grid>
    </CenterContainer>
  );
};
