import { Box, Flex } from "@chakra-ui/react";

export const DottedLabel = ({ children }: { children: React.ReactNode }) => {
  return (
    <Flex gap="10px" sx={{ alignItems: "center", paddingLeft: "6px" }}>
      <Box
        sx={{
          height: "6px",
          width: "6px",
          background: "#04D7B1",
          alignItems: "center",
        }}
      />
      <Box
        sx={{
          fontFamily: "Plus Jakarta Sans",
          fontWeight: 800,
          fontSize: "16px",
          color: "white",
        }}
      >
        {children}
      </Box>
    </Flex>
  );
};
