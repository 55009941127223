"use client";
import {
  Box,
  Flex,
  Grid,
  Image,
  keyframes,
  usePrefersReducedMotion,
} from "@chakra-ui/react";
import { useWindowScroll } from "@uidotdev/usehooks";

import { useEffect, useMemo, useRef, useState } from "react";
import Marquee from "react-fast-marquee";
import { CenterContainer } from "../shared/containers/CenterContainer";
import { DottedLabel } from "../shared/typography/DottedLabel";
import { BackgroundAnimation } from "./BackgroundAnimation/backgroundAnimation";
import { ForCreators } from "./ForCreators/forCreators";
import { WeAreMultichain } from "./WeAreMultichain/weAreMultichain";

const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

export const ModuleItem = ({
  name,
  label,
  icon,
  blurred = false,
  creator = false,
  reversed = false,
}: {
  name: string;
  label: string;
  icon: string;
  blurred?: boolean;
  reversed?: boolean;
  creator?: boolean;
}) => {
  return (
    <Flex
      align="center"
      justify="center"
      marginX={{ base: "30px", md: "25px" }}
      pos="relative"
      sx={{
        filter: blurred ? "blur(4px) grayscale(1)" : "none",
        display: "flex",
        // backdropFilter: "blur(24px)",
        paddingX: "40px",
        paddingRight: "30px",
        height: "64px",
        alignItems: "center",
        background: "rgba(0, 0, 0, 0.8)",
        fontWeight: 600,
        fontFamily: "Plus Jakarta Sans",
        position: "relative",
        fontSize: "18px",

        boxShadow:
          "0px 0.72474px 0.72474px 0px rgba(255, 255, 255, 0.25) inset, 0px 0px 10px 0px rgba(255, 255, 255, 0.05) inset",

        borderRadius: "12px",
        gap: "12px",
      }}
    >
      <Box opacity={blurred ? 0.7 : 1}> {name}</Box>

      <Box
        boxSize="48px"
        pos="absolute"
        bottom="0"
        left={reversed ? "initial" : "-24px"}
        right={reversed ? "-24px" : "initial"}
        margin="auto"
        top="0"
      >
        <Box boxSize="48px" pos="relative" className="circleBorder">
          <Box boxSize="48px">
            <Image
              borderRadius="50%"
              boxSize="48px"
              opacity={blurred ? 0.2 : 1}
              src={`/assets/modules/${icon}`}
            />
          </Box>
        </Box>
      </Box>
    </Flex>
  );
};

export const PlatformExplained = () => {
  const prefersReducedMotion = usePrefersReducedMotion();

  const animation = prefersReducedMotion
    ? undefined
    : `${spin} infinite 20s linear`;

  return (
    <>
      <Grid
        sx={{
          position: "relative",
          maxWidth: "1360px",
          margin: "auto",
        }}
        height={{ base: "auto", md: "2400px" }}
      >
        <Box pos="absolute" top="-100px" left="0" id="about" />

        <Flex
          height={{ base: "auto", md: "2400px" }}
          sx={{ position: "relative" }}
          flexDir="column"
          gap={{ base: "140px", md: "none" }}
        >
          <BackgroundAnimation />

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
            height={{ base: "auto", md: "600px" }}
            mt={{ base: "100px", md: "none" }}
          >
            <CenterContainer>
              <Image
                src="/assets/elements/stars-one.svg"
                pos="absolute"
                right="320px"
                top="300px"
              />
              <Image
                src="/assets/elements/stars-two.svg"
                pos="absolute"
                right="70px"
                top="100px"
              />
              <Flex flexDir="column" gap="10px">
                <DottedLabel>Community is the key of Mystra World</DottedLabel>
                <Box
                  sx={{
                    fontWeight: "bold",
                  }}
                  fontSize={{ base: "40px", md: "70px" }}
                  pos="relative"
                  lineHeight={{ base: "48px", md: "78px" }}
                >
                  Mystra connects
                  <br /> Community & Creators
                </Box>
                <Box
                  sx={{
                    maxWidth: "700px",
                    color: "#85858A",
                    fontFamily: "Plus Jakarta Sans",
                    fontWeight: "medium",
                    fontSize: "21px",
                    paddingTop: "20px",
                  }}
                >
                  As a creator, find a community interested in your products. As
                  a web3 user, look for useful and valuable projects.{" "}
                  <Box display="inline" color="white">
                    Mystra delivers, providing an engaging ecosystem.
                  </Box>{" "}
                  For creators, we offer the opportunity to present and develop
                  projects, giving the community a decisive voice.
                  <Box display="inline" color="white">
                    {" "}
                    It's pure symbiosis.
                  </Box>
                </Box>
              </Flex>
            </CenterContainer>
          </Box>
          <Box
            height={{ base: "auto", md: "600px" }}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <CenterContainer>
              <Grid
                w="100%"
                sx={{
                  display: "grid",
                  alignItems: "center",
                }}
                gridTemplateColumns={{ base: "1fr", md: "auto 380px" }}
                justifyContent="space-between"
              >
                <Box
                  display={{ base: "none", md: "flex" }}
                  sx={{ justify: "flex-start" }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",

                      gap: "16px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Flex direction={"row"}>
                      <ModuleItem
                        icon="staking.png"
                        label=""
                        name="Airdrops"
                        blurred
                      />
                    </Flex>
                    <Flex direction={"row"}>
                      <ModuleItem
                        icon="bridge.png"
                        label=""
                        name="Chain Explorer"
                      />
                      <ModuleItem icon="bridge.png" label="" name="Bridge" />
                      <ModuleItem icon="dao.png" label="" name="Airdrops" />
                    </Flex>
                    <Flex direction={"row"}>
                      <ModuleItem
                        icon="staking.png"
                        label=""
                        name="Hidden"
                        blurred
                      />
                      <ModuleItem icon="chat.png" label="" name="Web3 Chat" />
                      <ModuleItem icon="guilds.png" label="" name="Guilds" />
                      <ModuleItem icon="dex.png" label="dex.png" name="Dex" />
                    </Flex>
                    <Flex direction={"row"}>
                      <ModuleItem
                        icon="marketplace.png"
                        label=""
                        name="Marketplace"
                      />
                      <ModuleItem icon="fiat.png" label="" name="Buy Crypto" />

                      <ModuleItem icon="staking.png" label="" name="Staking" />
                    </Flex>
                    <Flex direction={"row"}>
                      <ModuleItem
                        icon="staking.png"
                        label=""
                        name="Hidden"
                        blurred
                      />
                      <ModuleItem
                        icon="wallet.png"
                        label=""
                        name="Crypto Wallet"
                      />
                    </Flex>
                  </Box>
                </Box>
                <Flex flexDir="column" width={{ base: "auto", md: "380px" }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "-40px",

                      fontWeight: "bold",
                      lineHeight: "56px",
                    }}
                    fontSize={{ base: "55px", md: "70px" }}
                    pos="relative"
                  >
                    <Box
                      color="#85858A"
                      fontSize={{ base: "30px", md: "33px" }}
                      lineHeight={"30px"}
                    >
                      We build for the
                    </Box>
                    <Box sx={{ letterSpacing: "-2px" }}>community</Box>
                    <Box
                      className="communityPlanet"
                      display={{ base: "flex", md: "none" }}
                      sx={{
                        height: "150px",

                        right: "0",
                        top: "-80px",
                        width: "150px",

                        position: "absolute",

                        borderRadius: "50%",
                      }}
                      zIndex="-1"
                    >
                      <Box
                        sx={{
                          position: "absolute",
                        }}
                      >
                        <Image
                          src="/assets/planet-community-right.svg"
                          alt="Planet"
                          objectFit="contain"
                        />
                      </Box>
                      <Box
                        sx={{
                          position: "absolute",
                        }}
                      >
                        <Image
                          opacity="0.8"
                          src="/assets/planet-community-left.svg"
                          alt="Planet"
                          objectFit="contain"
                        />
                      </Box>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      maxWidth: "380px",
                      color: "#85858A",
                      fontFamily: "Plus Jakarta Sans",
                      fontWeight: "medium",

                      display: "flex",
                      paddingTop: "30px",
                    }}
                    fontSize={{ base: "21px", md: "18px" }}
                  >
                    <Box>
                      {" "}
                      <Box display="inline" color="white">
                        Unite with your friends!
                      </Box>{" "}
                      Create or join guilds, chat with users directly or in
                      groups via wallet, participate in DAOs and more.{" "}
                      <Box display="inline" color="white">
                        You set the rules.
                      </Box>
                      <br />
                      <br />
                      Not sure where to start? No worries!{" "}
                      <Box display="inline" color="white">
                        Mystra provides all necessary tools{" "}
                      </Box>{" "}
                      to make community life easier and more efficient. Just
                      check it out.
                    </Box>
                  </Box>
                  <Box
                    mt="40px"
                    h="230px"
                    pos="relative"
                    display={{ base: "block", md: "none" }}
                  >
                    <Flex
                      flexDir="column"
                      align="space-between"
                      w="100vw"
                      left="-30px"
                      pos="absolute"
                      gap="18px"
                      h="100%"
                    >
                      <Marquee>
                        <ModuleItem
                          icon="staking.png"
                          label=""
                          name="Hidden"
                          blurred
                        />
                        <ModuleItem
                          icon="fiat.png"
                          label=""
                          name="Fiat Gateway"
                        />
                        <ModuleItem icon="guilds.png" label="" name="Guilds" />
                        <ModuleItem
                          icon="bridge.png"
                          label="dex.png"
                          name="Dex & Swap"
                        />
                      </Marquee>
                      <Marquee direction="right">
                        <ModuleItem
                          icon="bridge.png"
                          label=""
                          name="Chain Explorer"
                        />
                        <ModuleItem icon="bridge.png" label="" name="Bridge" />
                        <ModuleItem icon="dao.png" label="" name="Airdrops" />
                      </Marquee>
                      <Marquee>
                        <ModuleItem
                          icon="marketplace.png"
                          label=""
                          name="Marketplace"
                        />
                        <ModuleItem icon="chat.png" label="" name="Web3 Chat" />
                        <ModuleItem
                          icon="staking.png"
                          label=""
                          name="Staking"
                        />
                      </Marquee>
                    </Flex>
                  </Box>
                </Flex>
              </Grid>
            </CenterContainer>
          </Box>
          <ForCreators />
          <Flex
            sx={{
              alignItems: "center",
            }}
            pos="relative"
            overflowY="hidden"
            h={{ base: "calc(100vw - 30px)", md: "600px" }}
            my={{ base: "40px", md: "0" }}
            mb={{ base: "120px", md: "0" }}
          >
            <Flex
              sx={{
                flexDirection: "column",
                maxWidth: "100%",

                gap: "24px",
              }}
              pos="absolute"
              top="0"
              bottom="0"
              left="0"
              right="0"
              margin="auto"
              overflow={"hidden"}
              w="900px"
              boxSize={{ base: "90vw", md: "900px" }}
            >
              <Flex pos="relative" boxSize={{ base: "90vw", md: "900px" }}>
                <Box
                  background="linear-gradient(0deg, rgba(2,0,36,0) 0%, #090909 50%)"
                  zIndex={"-3"}
                  pos="absolute"
                  display={{ base: "none", md: "flex" }}
                  top="0"
                  w="100%"
                  height="500px"
                />
                <Box
                  background="linear-gradient(180deg, rgba(2,0,36,0) 0%,  #090909  50%)"
                  zIndex={"-3"}
                  pos="absolute"
                  bottom="0"
                  w="100%"
                  display={{ base: "none", md: "flex" }}
                  height="500px"
                />
                <Box
                  pos="absolute"
                  zIndex="-4"
                  top="0"
                  bottom="0"
                  w={{ base: "90vw", md: "900px" }}
                  height={{ base: "90vw", md: "900px" }}
                  animation={animation}
                >
                  <Box
                    pos="absolute"
                    w="100%"
                    height={{ base: "40px", md: "60px" }}
                    top="0"
                    transform={`rotate(0deg)`}
                    bottom="0"
                    margin="auto"
                  >
                    <Box w="100%" h="60px">
                      <Image
                        pos="absolute"
                        left="0"
                        boxSize={{ base: "40px", md: "60px" }}
                        src="/assets/blockchains/bnb.svg"
                      />
                      <Image
                        pos="absolute"
                        transform={"rotate(180deg)"}
                        right="0"
                        boxSize={{ base: "40px", md: "60px" }}
                        src="/assets/blockchains/cronos.svg"
                      />
                    </Box>
                  </Box>
                  <Box
                    pos="absolute"
                    w="100%"
                    height={{ base: "40px", md: "60px" }}
                    top="0"
                    transform={`rotate(30deg)`}
                    bottom="0"
                    margin="auto"
                  >
                    <Box w="100%" h="60px">
                      <Image
                        pos="absolute"
                        left="0"
                        boxSize={{ base: "40px", md: "60px" }}
                        src="/assets/blockchains/hidden.svg"
                      />
                      <Image
                        pos="absolute"
                        transform={"rotate(180deg)"}
                        right="0"
                        boxSize={{ base: "40px", md: "60px" }}
                        src="/assets/blockchains/ethereum.svg"
                      />
                    </Box>
                  </Box>
                  <Box
                    pos="absolute"
                    w="100%"
                    height={{ base: "40px", md: "60px" }}
                    top="0"
                    transform={`rotate(60deg)`}
                    bottom="0"
                    margin="auto"
                  >
                    <Box w="100%" h="60px">
                      <Image
                        pos="absolute"
                        left="0"
                        boxSize={{ base: "40px", md: "60px" }}
                        src="/assets/blockchains/arb.svg"
                      />
                      <Image
                        pos="absolute"
                        transform={"rotate(180deg)"}
                        right="0"
                        boxSize={{ base: "40px", md: "60px" }}
                        src="/assets/blockchains/polygon.svg"
                      />
                    </Box>
                  </Box>
                  <Box
                    pos="absolute"
                    w="100%"
                    height={{ base: "40px", md: "60px" }}
                    top="0"
                    transform={`rotate(90deg)`}
                    bottom="0"
                    margin="auto"
                  >
                    <Box w="100%" h="60px">
                      <Image
                        pos="absolute"
                        left="0"
                        boxSize={{ base: "40px", md: "60px" }}
                        src="/assets/blockchains/bnb.svg"
                      />
                      <Image
                        pos="absolute"
                        transform={"rotate(180deg)"}
                        right="0"
                        boxSize={{ base: "40px", md: "60px" }}
                        src="/assets/blockchains/hidden.svg"
                      />
                    </Box>
                  </Box>

                  <Box
                    pos="absolute"
                    w="100%"
                    height={{ base: "40px", md: "60px" }}
                    top="0"
                    transform={`rotate(150deg)`}
                    bottom="0"
                    margin="auto"
                  >
                    <Box w="100%" h="60px">
                      <Image
                        pos="absolute"
                        left="0"
                        boxSize={{ base: "40px", md: "60px" }}
                        src="/assets/blockchains/cspr.svg"
                      />
                      <Image
                        pos="absolute"
                        transform={"rotate(180deg)"}
                        right="0"
                        boxSize={{ base: "40px", md: "60px" }}
                        src="/assets/blockchains/polygon.svg"
                      />
                    </Box>
                  </Box>
                  <Box
                    pos="absolute"
                    w="100%"
                    height={{ base: "40px", md: "60px" }}
                    top="0"
                    transform={`rotate(120deg)`}
                    bottom="0"
                    margin="auto"
                  >
                    <Box w="100%" h="60px">
                      <Image
                        pos="absolute"
                        left="0"
                        boxSize={{ base: "40px", md: "60px" }}
                        src="/assets/blockchains/hidden.svg"
                      />
                      <Image
                        pos="absolute"
                        transform={"rotate(180deg)"}
                        right="0"
                        boxSize={{ base: "40px", md: "60px" }}
                        src="/assets/blockchains/cronos.svg"
                      />
                    </Box>
                  </Box>
                </Box>
              </Flex>
            </Flex>
            <WeAreMultichain />
          </Flex>
        </Flex>
      </Grid>
    </>
  );
};
