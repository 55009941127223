import { Box, Flex, Grid, Image, keyframes } from "@chakra-ui/react";
import { CenterContainer } from "../shared/containers/CenterContainer";
import { DottedLabel } from "../shared/typography/DottedLabel";

export const TicketIntro = () => {
  return (
    <CenterContainer>
      <Grid
        templateColumns={{ base: "1fr", md: "1fr 1fr" }}
        mt="00px"
        gap="60px"
      >
        <Flex flexDir="column" pos="relative">
          <Box pos="absolute" top="0" left="0" id="premium-pass" />

          <Image
            src="/assets/elements/ticket.png"
            display={{ base: "block", md: "none" }}
            pos="absolute"
            maxH="400px"
            margin="auto"
            transform={"translateY(20px)"}
            left="0"
            right="0"
            zIndex={3}
          />

          <Flex
            mt="120px"
            top="300px"
            zIndex={-1}
            justify="center"
            position="sticky"
            mb="40px"
          >
            <Box
              pos="absolute"
              width="180px"
              opacity={{ base: "1", md: "0" }}
              h="265px"
              zIndex={1}
              backdropFilter="blur(8px)"
            />
            <Image
              left="0"
              right="0"
              opacity={{ base: "1", md: "0" }}
              zIndex={3}
              mb="20px"
              height="270px"
              src="/assets/elements/ticket-mockup.svg"
            />
          </Flex>
          <Flex flexDir="column" gap="30px" bg="#090909" pos="relative">
            <Flex
              pos="absolute"
              w="100%"
              h="80px"
              top="-80px"
              zIndex={4}
              bg="#090909"
            ></Flex>

            <Flex
              pos="absolute"
              w="100%"
              h="160px"
              top="-230px"
              zIndex={4}
              bg="linear-gradient(transparent,#090909)"
            ></Flex>
            <Flex
              fontSize={{ base: "40px", md: "70px" }}
              lineHeight={{ base: "48px", md: "78px" }}
              fontWeight="bold"
              fontFamily="Inter Tight"
            >
              Want to be
              <br /> Early in Mystra?
            </Flex>
            <Box
              color="#85858A"
              maxW="400px"
              fontSize="21px"
              fontFamily={"Plus Jakarta Sans"}
            >
              With the Premium Pass* you get{" "}
              <Box display="inline" color="white">
                {" "}
                special benefits on our platform.
              </Box>
            </Box>
            <Box color="#85858A" maxW="370px">
              *Premium Pass is an NFT Token that can be minted on chains
              supported by Mystra.
            </Box>
            <Flex
              flexDir="column"
              align={{ base: "initial", md: "flex-start" }}
              gap="20px"
              mt="20px"
            >
              {[
                "Premium modules",
                "Launchpad allocations",
                "Ability to vote in DAO",
                "More Mystra Points",
                "Eligibility for Airdrops",
              ].map((item, index) => {
                return (
                  <Flex
                    align="center"
                    ml={{ base: "none", md: index % 2 == 1 ? "60px" : "0px" }}
                    height="55px"
                    paddingX="20px"
                    borderRadius="12px"
                    boxShadow={
                      "0px 0.72474px 0.72474px 0px rgba(255, 255, 255, 0.25) inset, 0px 0px 10px 0px rgba(255, 255, 255, 0.05) inset"
                    }
                    backdropBlur="12px"
                    gap="20px"
                    bg="#0A0A0A"
                    fontWeight="600"
                    fontFamily="Plus Jakarta Sans"
                    fontSize="18px"
                  >
                    <Box
                      border="7px solid #091E1B"
                      borderRadius="50%"
                      boxSize="26px"
                      bg="#04D7B1"
                    />{" "}
                    {item}
                  </Flex>
                );
              })}
            </Flex>
          </Flex>
          <Box display={{ base: "flex", md: "none" }} h="500px" />

          <Flex
            display={{ base: "none", md: "flex" }}
            flexDir="column"
            gap="30px"
            mt="150px"
          >
            <DottedLabel>When the time comes...</DottedLabel>

            <Box
              mt="-15px"
              fontSize={{ base: "40px", md: "70px" }}
              lineHeight={{ base: "48px", md: "78px" }}
              fontWeight="bold"
              fontFamily="Inter Tight"
            >
              Swap it & get $MYSTRA coin
            </Box>
            <Box
              color="#85858A"
              maxW="400px"
              fontSize="21px"
              fontFamily={"Plus Jakarta Sans"}
            >
              In the future, each{" "}
              <Box display="inline" color="white">
                Premium Pass can be exchanged for $MYSTRA coin.
              </Box>{" "}
              You will always need at least one pass to access the mentioned
              functions.
            </Box>
          </Flex>
        </Flex>
        <Grid
          display={{ base: "none", md: "grid" }}
          templateRows="1fr auto auto"
        >
          <Flex
            height="auto"
            mt="100px"
            flexDir="column"
            pos="relative"
            display={{ base: "none", md: "flex" }}
          >
            <Image
              src="/assets/elements/ticket.png"
              pos="absolute"
              zIndex={3}
            />

            <Flex
              mt="200px"
              top="300px"
              zIndex={2}
              justify="center"
              position="sticky"
              mb="40px"
            >
              <Box
                pos="absolute"
                width="180px"
                h="265px"
                zIndex={1}
                backdropFilter="blur(8px)"
              />
              <Image
                left="0"
                right="0"
                zIndex={2}
                mb="20px"
                height="270px"
                src="/assets/elements/ticket-mockup.svg"
              />
            </Flex>
          </Flex>
          <Flex pos="relative" height="80px" zIndex={0}>
            <Box
              boxSize="400px"
              pos="absolute"
              className="creatorPlanet"
              top="0"
              bottom="0"
              left="0"
              zIndex={1}
              right="0"
              margin="auto"
            />
            <Box
              boxSize="500px"
              border="1px dashed rgba(255,255,255,0.2)"
              pos="absolute"
              borderRadius="50%"
              top="0"
              bottom="0"
              left="0"
              right="0"
              zIndex={2}
              margin="auto"
            />
            <Image
              pos="absolute"
              src="/assets/arrow-spinning.svg"
              top="0"
              bottom="0"
              left="0"
              right="0"
              margin="auto"
            />
            <Image
              pos="absolute"
              src="/assets/elements/spinning.png"
              top="0"
              bottom="0"
              left="0"
              w="300px"
              pl="15px"
              pb="15px"
              right="0"
              margin="auto"
            />
          </Flex>
          <Flex h="300px" pos="relative">
            <Image
              src="/assets/elements/coins.svg"
              pos="absolute"
              boxSize="400px"
              zIndex={2}
              left="0"
              right="0"
              margin="auto"
            />

            <Box
              pos="absolute"
              borderRadius="50%"
              backdropFilter="blur(4px)"
              boxSize="200px"
              zIndex={1}
              top="90px"
              left="0"
              right="0"
              margin="auto"
            />
          </Flex>
        </Grid>

        <Flex
          flexDir="column"
          gap="30px"
          display={{ base: "block", md: "none" }}
        >
          <Flex pos="relative" height="0px" mt="-40px">
            <Box
              boxSize="200px"
              pos="absolute"
              className="creatorPlanet"
              top="0"
              bottom="0"
              left="0"
              zIndex={1}
              right="0"
              margin="auto"
            />
            <Box
              boxSize="300px"
              border="1px dashed rgba(255,255,255,0.2)"
              pos="absolute"
              borderRadius="50%"
              top="0"
              bottom="0"
              left="0"
              right="0"
              margin="auto"
            />
            <Image
              pos="absolute"
              src="/assets/arrow-spinning.svg"
              top="0"
              bottom="0"
              left="0"
              right="0"
              margin="auto"
            />
            <Image
              pos="absolute"
              src="/assets/elements/spinning.png"
              top="0"
              bottom="0"
              left="0"
              w="200px"
              pl="10px"
              pb="10px"
              right="0"
              margin="auto"
            />
          </Flex>
          <Flex h="300px" pos="relative" display={{ base: "flex", md: "none" }}>
            <Image
              src="/assets/elements/coins.svg"
              pos="absolute"
              boxSize="250px"
              maxW="80vw"
              zIndex={2}
              left="0"
              right="0"
              top="20px"
              margin="auto"
            />
            <Box
              pos="absolute"
              backdropFilter="blur(4px)"
              boxSize="150px"
              zIndex={1}
              left="0"
              top="100px"
              borderRadius="50%"
              right="0"
              margin="auto"
            />
          </Flex>
          <DottedLabel>When the time comes...</DottedLabel>
          <Box
            mt="10px"
            fontFamily="Inter Tight"
            sx={{
              fontWeight: "bold",
            }}
            fontSize={{ base: "40px", md: "70px" }}
            lineHeight={{ base: "48px", md: "78px" }}
          >
            Swap it & get $MYSTRA coin
          </Box>
          <Box
            color="#85858A"
            mt="30px"
            maxW="400px"
            fontSize="21px"
            fontFamily={"Plus Jakarta Sans"}
          >
            In the future, each{" "}
            <Box display="inline" color="white">
              Premium Pass can be exchanged for $MYSTRA coin.{" "}
            </Box>
            You will always need at least one pass to access the mentioned
            functions.
          </Box>
        </Flex>
      </Grid>
    </CenterContainer>
  );
};
