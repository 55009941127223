import { Box, Flex } from "@chakra-ui/react";
import { CenterContainer } from "../../shared/containers/CenterContainer";

export const WeAreMultichain = () => {
  return (
    <CenterContainer>
      <Flex
        height={{ base: "auto", md: "600px" }}
        margin="auto"
        justify="center"
        align="center"
      >
        <Flex align="center" justify="center">
          <Flex
            sx={{
              textAlign: "center",

              display: "flex",
              flexDirection: "column",
              gap: "12px",
              paddingBottom: "20px",

              fontWeight: "bold",
              lineHeight: "56px",
            }}
            fontSize={{ base: "40px", md: "97px" }}
            lineHeight={{ base: "0px", md: "56px" }}
          >
            <Box
              fontSize={{ base: "30px", md: "48px" }}
              lineHeight={{ base: "20px", md: "78px" }}
            >
              We are
            </Box>
            <Box>Multichain</Box>
          </Flex>
        </Flex>
      </Flex>
    </CenterContainer>
  );
};
