import { useState } from "react";
import { AdditionalInfo } from "./AdditionalInfo/additionalInfo";
import { AdditionalInfoTwo } from "./AdditionalInfoTwo/additionalInfoTwo";
import { Footer } from "./Footer/footer";
import { CurrentOffer } from "./invest/CurrentOffer/currentOffer";
import { InvestHeader } from "./InvestHeader";
import { InvestorBuyWindow } from "./InvestorBuyWindow/investorBuyWindow";
import { InvestorHeader } from "./InvestorHeader/investorHeader";
import { RoundsComparison } from "./RoundsComparison/roundsComparison";
import { SaleView } from "./SaleView/saleView";
import { SelectRound } from "./SelectRound/selectRound";

export const InvestPage = () => {
  const [amount, setAmount] = useState<number>(50);

  return (
    <>
      <InvestHeader />

      <SelectRound />
      <SaleView amount={amount} />
      <RoundsComparison />
      <InvestorBuyWindow
        currentDiscount={0}
        currentRound={0}
        cryptoPrice={34}
        setAmountGot={() => {}}
        amount={amount}
        setAmount={setAmount}
      />
      <AdditionalInfo />
      <AdditionalInfoTwo />
      <Footer />
    </>
  );
};
